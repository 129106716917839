import {DatasetManagerDatasetEntity} from '@biostrand/biostrandapi/javascript/dist/DatasetManagerApi';
import {ArrowBack} from '@mui/icons-material';
import {Button, Typography} from '@mui/material';
import {Stack} from '@mui/system';
import {GridSelectionModel} from '@mui/x-data-grid/models/gridSelectionModel';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {PopupWindow} from '../../popup/PopupWindow';
import {refreshDatasetsAction} from '../../slices/datasets/datasetsSlice';
import {COLUMN_NAMES} from '../datasetGrid/datasetColumns';
import {DatasetSingleFileSelector} from './DatasetSingleFileSelector';
import {DGDatasetsListBase} from "../datasetGrid/DGDatasetsListBase";
import {getDatasetByName} from "../datasetAPIUtils";

interface SelectDatasetPopupProps {
  popupKey: string;
  selectedDataset?: GridSelectionModel;
  open: boolean;
  onDatasetOrFileSelectionChange: (selectedDatasetIds: GridSelectionModel) => void;
  onCancel: () => void;
}
export const SelectDatasetOrFilePopup = (props: SelectDatasetPopupProps) => {
  const { onCancel, selectedDataset, open, onDatasetOrFileSelectionChange } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [currentSelection, setCurrentSelection] = useState<GridSelectionModel | undefined>(selectedDataset);
  const [openDataset, setOpenDataset] = useState<GridSelectionModel | undefined>(selectedDataset);
  const [selectedFile, setSelectedFile] = useState<GridSelectionModel | undefined>();
  const [dataset, setDataset] = useState<DatasetManagerDatasetEntity | undefined>();

  useEffect(() => {
    dispatch(refreshDatasetsAction());
  }, []);

  useEffect(() => {
    if (currentSelection && currentSelection.length > 0) {
      const loadDataset = async () => {
        const ds = await getDatasetByName(currentSelection[0] as string)
        setDataset(ds);
      }
      loadDataset();
    }
  }, [currentSelection]);

  const onClose = () => {
    onCancel();
  };

  const onOpenDataset = (selection: GridSelectionModel) => {
    setOpenDataset(selection);
  };

  const onSelectionChange = (selection: GridSelectionModel) => {
    setCurrentSelection(selection);
  };

  const currentColumns = [COLUMN_NAMES.name_and_version, COLUMN_NAMES.last_updated_on, COLUMN_NAMES.files]

  return (
    <PopupWindow open={open} title={t('Select dataset or file')} onClose={onClose}>
      <Stack direction={'column'} sx={{ minHeight: 700, minWidth:900, flex:1}}>
        {openDataset && openDataset[0] ? (
          <Stack direction={'column'} sx={{flex:1}}>
            <Button
              onClick={() => {
                setSelectedFile(undefined);
                setOpenDataset(undefined);
                setCurrentSelection(undefined);
              }}
              sx={{ maxWidth: 180, mb: 1 }}
              startIcon={<ArrowBack />}
            >
              Back to datasets
            </Button>
            <DatasetSingleFileSelector
              datasetId={String(openDataset[0])}
              onSelectionChange={fileIds => setSelectedFile(fileIds)}
            />

            <Stack direction={'row'} spacing={2} sx={{ justifyContent: 'flex-end', mt: 2, mb: 2 }}>
              {selectedFile && selectedFile.length > 0 ? (
                <Typography sx={{ flex: 1, fontWeight: '500' }} variant={'subtitle1'} color={'primary.main'}>
                  {t('Selected: ')} {selectedFile}
                </Typography>
              ) : null}
              <Button variant={'outlined'} onClick={onClose}>
                {t('cancel')}
              </Button>
              <Button
                variant={'contained'}
                disabled={!selectedFile}
                onClick={() => {
                  onDatasetOrFileSelectionChange &&
                  selectedFile &&
                  onDatasetOrFileSelectionChange([`${openDataset[0]}${selectedFile}`]);
                }}
              >
                Select
              </Button>
            </Stack>
          </Stack>
        ) : (
          <Stack sx={{flex:1, height:600 }}>
            <DGDatasetsListBase
              selectedDatasets={selectedDataset}
              onDatasetSelect={onSelectionChange}
              onDatasetOpen={onOpenDataset}
              minHeight={500}
              columns={currentColumns}
            />

            <Stack direction={'row'} spacing={2} sx={{ justifyContent: 'flex-end', mt: 2, mb: 2  }}>
              {dataset && (
                <Typography sx={{ flex: 1, fontWeight: '500' }} variant={'subtitle1'} color={'primary.main'}>
                  {t('Selected: ')} {dataset.name}
                </Typography>
              )}
              <Button variant={'outlined'} onClick={onClose}>
                {t('cancel')}
              </Button>
              <Button
                variant={'contained'}
                disabled={!currentSelection}
                onClick={() => {
                  onDatasetOrFileSelectionChange &&
                    currentSelection &&
                    onDatasetOrFileSelectionChange(currentSelection);
                }}
              >
                {t('Select')}
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>
    </PopupWindow>
  );
};
