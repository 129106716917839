export enum CommonRuntimeVariables{
    CognitoUserPoolId = 'cognitoUserPoolId',
    CognitoRegion = 'cognitoRegion',
    CognitoIdentityPoolId = 'cognitoIdentityPoolId',
    CognitoClientId = 'cognitoClientId',
    Features = 'features',
    AppsLinks = 'apps',
    PortalUrl = 'portalUrl',
    LoginUrl = 'loginUrl',
    DisableIntercom = 'disableIntercom',
    DisableMolStarLLM = 'disableMolStarLLM'
}