import {
    AuthenticationStatus,
    BOOTSTRAP,
    bootstrapAppAction,
    datasetsSaga,
    getIsAuthenticated,
    handleLogout,
    logout,
    logoutSaga,
    QueryParameterUtils,
    RuntimeConfig,
    setCurrentApp,
    showError,
    uApi,
    updateAuthStatus,
} from '@biostrand/components';
import { initUser } from '@biostrand/components/src/applications/appCommonSagas';
import { PORTAL_BASE_NAME } from '@biostrand/components/src/applications/applicationUtils';
import { jobsSaga } from '@biostrand/components/src/jobs/jobsSaga';
import routeUtilSaga from '@biostrand/components/src/portalLink/routeUtilSagas';
import { fileUploadSaga } from '@biostrand/components/src/slices/datasets/fileUploadManagerSaga';
import { initFileUploadManager } from '@biostrand/components/src/slices/datasets/fileUploadManagerSlice';
import { commonUsersSaga } from '@biostrand/components/src/slices/user/userSaga';
import { push } from 'connected-react-router';
import { LocationDescriptorObject } from 'history';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { loginRequest } from './core/auth/authActions';
import { authenticate } from './core/auth/cognitoUtils';
import { createOrganizationRequestAction, OrganizationActionTypes } from './core/organization/organizationActions';
import { setOrganizationCreating } from './core/organization/organizationSlice';
import { usersSaga } from './pages/private/settings/users/usersSaga';
import { LOGIN_REQUEST } from './pages/public/registration/registerTypes';
import authenticationSaga from './pages/public/registration/registrationSagas';
import { RouteUrl } from './routeUrl';

// portal has different bootstrap login than other apps. It checks for the login, registration and change passwords flows.
function* handleApplicationBootstrap(action: ActionType<typeof bootstrapAppAction>) {
    const currentApp = action.payload;
    yield put(setCurrentApp(currentApp));

    try {
        const isAuthenticated = yield call(getIsAuthenticated);
        if (!isAuthenticated) {
            yield put(updateAuthStatus(AuthenticationStatus.NOT_AUTHENTICATED));

            if (
                window.location.pathname === `/${PORTAL_BASE_NAME}${RouteUrl.ConfirmPassword}` ||
                window.location.pathname === `/${PORTAL_BASE_NAME}${RouteUrl.Login}` ||
                window.location.pathname === `/${PORTAL_BASE_NAME}${RouteUrl.RegisterOrganization}`
            ) {
                return;
            }

            const currentLocation = `${window.location.origin}${window.location.pathname}`;

            if (currentLocation !== RuntimeConfig.loginUrl()) {
                yield put(
                    push({ pathname: RouteUrl.Login, search: `sourceUrl=${encodeURIComponent(currentLocation)}` }),
                );
            }
            return;
        }
    } catch (err) {
        yield put(updateAuthStatus(AuthenticationStatus.NOT_AUTHENTICATED));
        yield put(push(RouteUrl.Login));
        yield handleLogout();
        return;
    }

    const user = yield initUser();

    if (!user) {
        yield put(updateAuthStatus(AuthenticationStatus.NOT_AUTHENTICATED));
        yield showError('Failed to get user details, try again later', 'no user found');
        yield put(push(RouteUrl.Login));
        yield handleLogout();
        return;
    }

    yield put(updateAuthStatus(AuthenticationStatus.AUTHENTICATED));
    yield put(initFileUploadManager());
    // yield put(refreshJobTypesAction());
}

function* handleLogin(action: ActionType<typeof loginRequest>) {
    const { sourceUrl, credentials } = action.payload;
    yield put(updateAuthStatus(AuthenticationStatus.PENDING));
    try {
        const result = yield call(authenticate, credentials);
        if (!result) {
            yield put(updateAuthStatus(AuthenticationStatus.NOT_AUTHENTICATED));
            return;
        }
    } catch (err) {
        yield showError('Failed to login, please check your credentials');
        return;
    }

    const user = yield initUser();

    if (!user) {
        yield showError('Failed to get user details, please check your connection', 'no user found');
        yield call(logout);
        yield put(updateAuthStatus(AuthenticationStatus.NOT_AUTHENTICATED));
        return;
    }

    if (sourceUrl) {
        window.location.href = decodeURIComponent(sourceUrl);
    } else {
        yield put(updateAuthStatus(AuthenticationStatus.AUTHENTICATED));
        yield put(push(RouteUrl.Root));
        yield put(initFileUploadManager());
    }
}

function* handleCreateOrganizationRequest(action: ActionType<typeof createOrganizationRequestAction>) {
    try {
        const request = action.payload;
        yield put(setOrganizationCreating(true));
        const result = yield call([uApi.getUserManagerApi(), 'userManagerCreateOrganization'], request);
        if (result) {
            const location: LocationDescriptorObject = {
                pathname: RouteUrl.ConfirmationUnderway,
                search: QueryParameterUtils.updateQueryParameters({ email: request.email, onboarding: 'true' }, ''),
            };
            yield put(push(location));
        }
    } catch (err) {
        yield showError('Something went wrong, try to reload page', err.toString());
    } finally {
        yield put(setOrganizationCreating(false));
    }
}
//
// function* handlePasswordChallengeRequest(action: ActionType<typeof completePasswordChallengeRequest>) {
//     try {
//         yield put(passwordConfirmationStatus({ status: RequestStatus.IN_PROGRESS }));
//         const result = yield call(finishNewPasswordChallenge, action.payload);
//         if (result) {
//             yield put(passwordConfirmationStatus({ status: RequestStatus.SUCCESS }));
//             // Show notification
//             // redirect to login ?
//         }
//     } catch (err) {
//         yield put(passwordConfirmationStatus({ status: RequestStatus.FAILED }));
//     }
// }

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* appSaga() {
    yield all([
        yield takeEvery(BOOTSTRAP, handleApplicationBootstrap),
        yield takeEvery(LOGIN_REQUEST, handleLogin),
        yield takeEvery(OrganizationActionTypes.CREATE_ORGANIZATION_REQUEST, handleCreateOrganizationRequest),

        yield fork(authenticationSaga),
        yield fork(usersSaga),
        yield fork(commonUsersSaga),

        /* common sagas */
        yield fork(logoutSaga),
        yield fork(routeUtilSaga),
        yield fork(datasetsSaga),
        yield fork(fileUploadSaga),
        yield fork(jobsSaga),
        /* eof */
    ]);
}

export default appSaga;
