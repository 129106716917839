import React, {useEffect, useState} from 'react';
import {getMolstarContentType, isSupportedByMolstar, loadStructure} from "./MolstarUtils";
import {getFileExtension} from "../datasets/fileupload/fileUtils";
import {LoadingButton} from "@mui/lab";
import {Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Typography} from "@mui/material";
import {Stack} from "@mui/system";
import {getFileFolder, getFileName} from "../datasets/fileViewer/filePreviewUtils";
import {loadTextFile} from "../datasets/fileViewer/loadFile";


export const MolstarViewerLoadMore = ({fileList, molPlugin}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [supportedFiles, setSupportedFiles] = useState([])
  const [checked, setChecked] = useState([])

  const [areFilesLoading, setAreFilesLoading] = useState(false);

  const handleToggle = (path: string) => () => {
    const currentIndex = checked.indexOf(path);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(path);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  useEffect(() => {
    if (fileList) {
      const newSupportedFiles = fileList.filter((f) => isSupportedByMolstar(getFileExtension(f.name)));
      setSupportedFiles(newSupportedFiles);
    }
  }, [fileList])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setChecked([]);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return <>
    <LoadingButton aria-describedby={id} onClick={handleClick} sx={{display: molPlugin ? 'initial' : 'hidden'}}
                   disabled={areFilesLoading} loading={areFilesLoading}>
      Load more...
    </LoadingButton>
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {supportedFiles.length > 0 ?
        <Stack sx={{p: 1}}>
          <Typography sx={{p: 2}}>Select file to add</Typography>
          <List sx={{
            width: '100%',
            maxHeight: 300,
            overflowY: 'auto',
            minWidth: 360,
            maxWidth: 600,
            bgcolor: 'background.paper'
          }}>
            {supportedFiles.map((file) => {
              const labelId = `checkbox-list-label-${file.name}`;

              return (
                <ListItem
                  key={file.name}
                  disablePadding
                  disableGutters
                >
                  <ListItemButton role={undefined}  sx={{ height: 48 }} onClick={handleToggle(file.name)}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked.includes(file.name)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{'aria-labelledby': labelId}}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={getFileName(file.name)} secondary={getFileFolder(file.name)}/>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
          <LoadingButton loading={areFilesLoading} disabled={areFilesLoading} onClick={async () => {
            setAreFilesLoading(true)
            try {
              if (molPlugin && checked.length > 0) {
                for (let i = 0; i < checked.length; i++) {
                  const file = fileList.find(f => f.name === checked[i]);
                  const content = await loadTextFile(file.name, file.dataset_id);
                  await loadStructure(content, file.name, getMolstarContentType(getFileExtension(file.name)), molPlugin, false);
                }
                handleClose()
              }
            } catch (e) {
              console.log(e);
            } finally {
              setAreFilesLoading(false)

            }
          }}>Load</LoadingButton>
        </Stack> : <Typography sx={{p: 2}}>Nothing to load</Typography>}
    </Popover>
  </>
}
