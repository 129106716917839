import {DatasetManagerDatasetEntity} from "@biostrand/biostrandapi/javascript/dist/DatasetManagerApi";
import {getRqlQueryString} from "../filters/rqlUtils";
import {FieldFilterType, FilterType} from "../filters/dataFiltersTypes";
import {uApi} from "../biostrandApi/uApi";

export const getDatasets = async (ids: string[]): DatasetManagerDatasetEntity[] | undefined  => {
    const query = btoa(getRqlQueryString('', [{
            field: 'id',
            type: FilterType.FIELD,
            filterItemKey: 'datasetId',
            fieldType: FieldFilterType.STRING,
            value: {$in: ids}
        }]
    ));
    const dsResult = await uApi.getDatasetManagerApi().datasetManagerGetDatasets(query, ids.length);
    return dsResult.data?.data;
}

export const getDatasetByName = async (name: string): DatasetManagerDatasetEntity | undefined  => {
    const query = btoa(getRqlQueryString('', [{
            field: 'id',
            type: FilterType.FIELD,
            filterItemKey: 'name',
            fieldType: FieldFilterType.STRING,
            value: {$in: [name]}
        }], [{field: 'version', sort: 'desc'}]
    ));

    const dsResult = await uApi.getDatasetManagerApi().datasetManagerGetDatasets(query, 1);
    return dsResult.data?.data ? dsResult.data?.data[0] : undefined;
}