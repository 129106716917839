import {FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack} from '@mui/material';
import {useFormik} from 'formik';
import 'molstar/build/viewer/molstar.css';
import React from 'react';
import {enqueueSnackbarNotification} from "../snackbarNotification/snackbarNotificationSlice";
import {CloseNotificationButton, uApi} from "../index";
import {useDispatch} from "react-redux";
import {LoadingButton} from "@mui/lab";
import {Notes} from "@mui/icons-material";
import trim from "lodash.trim";
import {parsePyMOLCommands, PyMOLCommand} from "./MolstarUtils";
import {showPopup} from "../popup/popupsSlice";
import {MolstarChatQueryHistoryPopup} from "./MolstarChatQueryHistoryPopup";

interface MoldtarChatQueryProps {
  onCommand: (commands: PyMOLCommand[]) => void;
}

const extractPymolCommands = (response: { text: string }): string | null => {
  let commandsStr =  trim(response.text, '`"').replace(/\b(pymol|python)\b/g, '').trim();
  return parsePyMOLCommands(commandsStr);
}

const popupKey = "Query-Chat-History";

export const MoldtarChatQuery = (props: MoldtarChatQueryProps) => {
  const {onCommand} = props;
  const dispatch = useDispatch();
  const [history, setHistory] = React.useState([]);

  const onItemHistory = (historyItem) => {
    chatQueryForm.setFieldValue('query', historyItem.request);
  }

  const chatQueryForm = useFormik({
    initialValues: {
      query: 'show me all residues within 5 angstrom from residue 1',
    },

    onSubmit: async (values) => {
      try {
        const response = await uApi.getChatGPTApi().chatGPTChat({message: values.query});
        const commands = extractPymolCommands(response.data);

        if (onCommand && commands) {
          onCommand(commands);
        }

        setHistory([{request: values.query, response: response.data.text, date: new Date()}, ...history])
      } catch (e: Error) {
        dispatch(
          enqueueSnackbarNotification({
            message: e.toString(),
            key: 'delete-error',
            options: {
              variant: 'warning',
              persist: false,
              action: key => <CloseNotificationButton
                notificationKey={key}>Close</CloseNotificationButton>,
            },
          }),
        );
      }
    }
  });

  return (

    <form onSubmit={chatQueryForm.handleSubmit}>
      <Stack spacing={1} direction={"row"}>
        <FormControl sx={{minWidth: 400}} variant="outlined">
          <InputLabel htmlFor="pymolQuery" size={"small"}>Ask</InputLabel>
          <OutlinedInput
            endAdornment={<InputAdornment position="end">
              <IconButton size={"small"} title={'history'} onClick={()=> {
                dispatch(
                  showPopup({
                    key: popupKey,
                    title: 'Chat History',
                    content: React.createElement(MolstarChatQueryHistoryPopup, {
                      popupKey: popupKey,
                      history: history,
                      onItemHistory: onItemHistory
                    }),
                  }),
                );
              }
              }>
                <Notes /></IconButton>
            </InputAdornment>}
            aria-describedby=""
            inputProps={{
              'aria-label': 'Ask',
            }}
            id="ask"
            label="Ask"
            size={"small"}
            sx={{minWidth: 400}}
            name="query"
            value={chatQueryForm.values.query}
            onChange={chatQueryForm.handleChange}
          />
        </FormControl>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={chatQueryForm.isSubmitting}
          onClick={() => {
            chatQueryForm.handleSubmit();
          }}
        >
          {'Send'}
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default MoldtarChatQuery;
