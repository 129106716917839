import {DatasetManagerDatasetEntity,} from '@biostrand/biostrandapi/javascript/dist/DatasetManagerApi';
import {LinearProgress, Stack} from '@mui/material';
import {GridSelectionModel} from '@mui/x-data-grid/models/gridSelectionModel';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {MenuItemDescription} from '../../grid/menuTypes';
import {
  COLUMN_NAMES,
  CREATED_DATE_COLUMN,
  getMenuColumn,
  LAST_UPDATED_DATE_COLUMN,
  NAME_WITH_STATUS_COLUMN,
  NAME_WITH_VERSION_COLUMN,
  SHOW_CONTENT_COLUMN,
} from './datasetColumns';
import {StringValueFilterInput} from "../filters/StringValueFilterInput";
import {MultipleValueFilterSelector} from "../filters/MultipleValueFilterSelector";
import {DATASET_STATUSES} from "../filters/dsFiltersConstants";
import {DateFilterInput} from "../filters/DateFilterInput";
import {useDataGridProvider} from "../../grid/useDataGridProvider";
import {DataGridPro, GridColDef, GridSortModel} from "@mui/x-data-grid-pro";
import {datasetsFetch} from "./dgDatasetListUtils";

const getDatasetColumns = (onClick: (item: DatasetManagerDatasetEntity) => void):GridColDef[] => {
  return [
    NAME_WITH_STATUS_COLUMN,
    NAME_WITH_VERSION_COLUMN,
    CREATED_DATE_COLUMN,
    LAST_UPDATED_DATE_COLUMN,
    SHOW_CONTENT_COLUMN(onClick),
  ];
};

export type DatasetsGridProps = {
  selectedDatasets?: GridSelectionModel;
  showMenu?: boolean;
  initialSort?: GridSortModel;
  onDatasetSelect: (selection: GridSelectionModel) => void;
  onDatasetOpen: (selection: GridSelectionModel) => void;
  menuItems?: MenuItemDescription[];
  onMenuAction?: (row: DatasetManagerDatasetEntity[], action: string) => void;
  columns?: COLUMN_NAMES[];
  hideSearch?: boolean;
  hideHeader?: boolean;
  hideFooter?: boolean;
  minHeight?:number;
  name?: string;
};

export const DGDatasetsListBase = (props: DatasetsGridProps): JSX.Element => {
  const {
    name = 'DGDatasetsListBase',
    showMenu,
    menuItems,
    onMenuAction,
    selectedDatasets,
    onDatasetSelect,
    onDatasetOpen,
    minHeight = 400,
    hideFooter,
    hideHeader,
    initialSort = [{ field: 'last_updated_on', sort: 'desc' }],
    columns = [COLUMN_NAMES.menu, COLUMN_NAMES.name, COLUMN_NAMES.created_on, COLUMN_NAMES.last_updated_on],
  } = props;

  const initialSettings = {
    //prefix: 'dataset.',
    paginationModel: {
      page: 0,
      pageSize: 100
    },
    sortModel: initialSort
  }

  const [datasets = [], totalPages, isLoading, error, setPaginationModel, setSortModel, tryAgain] = useDataGridProvider(name, datasetsFetch, initialSettings);

  const [currentSelection, setCurrentSelection] = useState<GridSelectionModel | undefined>(selectedDatasets);
  const [dsColumns, setDsColumns] = useState<GridColDef[]>([]);

  const onClick = (row:DatasetManagerDatasetEntity) => {
    if (row.id) {
      onDatasetOpen([row.id] );
    }
  };

  useEffect( () => {
    const datasetColumns:GridColDef[] = getDatasetColumns(onClick).filter(column_instance => columns.indexOf(column_instance.field) > -1);
    if (showMenu && menuItems && onMenuAction) {
      datasetColumns.unshift(getMenuColumn(menuItems, onMenuAction));
    }

    setDsColumns(datasetColumns);
  }, [showMenu, menuItems, onMenuAction, onDatasetOpen]);


  const onRowSelection = (rowSelectionModel: GridSelectionModel) => {
    onDatasetSelect(rowSelectionModel);
    setCurrentSelection(rowSelectionModel);

  };

  return (
    <Stack
      direction={'column'}
      sx={{ minWidth: 300, flex: 1 }}
    >
      <Stack direction={"row"} sx={{mb:0.5, alignItems: "center", flexWrap: 'wrap',}}>
        <StringValueFilterInput filterKey={name} field={'name'} filterItemKey={"name"} sx={{mr:1}} />
        <MultipleValueFilterSelector values={DATASET_STATUSES} filterKey={name} field={'status'} filterItemKey={"status"} fieldLabel={"Status"} />
        <DateFilterInput
          fieldLabel={"Created"}
          filterKey={name}
          field={'created_on'}
          filterItemKey={"created_on"}/>
        <DateFilterInput
          filterKey={name}
          fieldLabel={"Last Updated"}
          field={'last_updated_on'}
          filterItemKey={"last_updated_on"}/>

      </Stack>
      <Stack  sx={{height: minHeight, flex:1, position: "relative" }} >
        {datasets ? (
        <DataGridPro
          sx={{
            '.MuiDataGrid-root': { border: '#f00 5px' },
            position: 'absolute',
            border: '5px',
            top: 0,
            bottom: 0,
            left: 0,
            right:0
          }}

          initialState={initialSettings}
          pagination
          sortingMode='server'
          onSortModelChange={setSortModel}
          loading={isLoading}
          getRowHeight={() => 'auto'}
          rowCount={totalPages || 1000}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          rows={datasets}
          columns={dsColumns}
          hideFooter={hideFooter}
          headerHeight={ hideHeader ? 0 : 56 }
          getRowId={row => row.id || '' }
          onRowSelectionModelChange={ onRowSelection }
          disableColumnFilter
          hideFooterSelectedRowCount
          keepNonExistentRowsSelected
          selectionModel={currentSelection}
          density={'standard'}
        />
      ) : null}
      </Stack>

      {isLoading ? (
        <div
          style={{
            left: 0,
            top: 0,
            zIndex: 1050,
            backgroundColor: 'rgba(255,255,255,0.7)',
            bottom: 0,
            paddingTop: 0,
            right: 0,
            position: 'absolute',
          }}
        >
          <LinearProgress />{' '}
        </div>
      ) : null}
    </Stack>
  );
};
