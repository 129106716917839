import {
    InlineObject1,
    InlineObject2,
    UserManagerUserEntity,
} from '@biostrand/biostrandapi/javascript/dist/UserManagerApi';
import { OrganizationUserEntity } from '@biostrand/components/src/slices/user/userTypes';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrganizationRole } from '../../../../core/organization/organizationTypes';
import { UsersState, UserStatus } from './usersTypes';

export const REFRESH_USERS = '@organisation/users/REFRESH_USERS';
export const DELETE_USER = '@organisation/users/DELETE_USER';
export const INVITE_USER_REQUEST = '@organisation/users/INVITE_USER_REQUEST';
export const UPDATE_USER_ROLE = '@organisation/users/UPDATE_USER_ROLE';
export const UPDATE_USER_STATUS = '@organisation/users/UPDATE_USER_STATUS';

interface InviteUserRequest {
    invite: InlineObject1;
    callback: (error: string | undefined) => void;
}

interface UpdateUserRoleRequest {
    user: InlineObject2;
    role: OrganizationRole;
}

interface UpdateUserStatusRequest {
    user: InlineObject2;
    status: UserStatus;
}

export const refreshUsersAction = createAction(REFRESH_USERS);
export const deleteUsersAction = createAction<UserManagerUserEntity>(DELETE_USER);

export const inviteUserRequestAction = createAction<InviteUserRequest>(INVITE_USER_REQUEST);
export const updateUserRoleAction = createAction<UpdateUserRoleRequest>(UPDATE_USER_ROLE);
export const updateUserStatusAction = createAction<UpdateUserStatusRequest>(UPDATE_USER_STATUS);

const initialState: UsersState = {
    users: undefined,
    isLoading: false,
};

export const usersSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateUsers: (state, action: PayloadAction<UserManagerUserEntity[] | undefined>) => {
            const organizationUsers: OrganizationUserEntity[] = action.payload
                ? [
                      ...action.payload.flatMap(user =>
                          user.organizations
                              ? user.organizations?.map(
                                    org =>
                                        ({
                                            email: user.email,
                                            status: org.status,
                                            organization_role: org.organization_role,
                                            created_on: user.created_on,
                                            last_updated_on: user.last_updated_on,
                                            applications: user.applications,
                                        } as OrganizationUserEntity),
                                )
                              : [],
                      ),
                  ]
                : [];

            state.users = organizationUsers;
        },
        setUsersLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
    },
});

export const { updateUsers, setUsersLoading } = usersSlice.actions;

export default usersSlice.reducer;
