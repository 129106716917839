import {DatasetManagerDatasetItem, SharedObjectType} from '@biostrand/biostrandapi/javascript/dist/DatasetManagerApi';
import {ArrowBack, ChevronRight, Folder, Visibility} from '@mui/icons-material';
import {Breadcrumbs, Checkbox, IconButton, LinearProgress, Link, Stack, Typography,} from '@mui/material';
import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {DataGrid, GridRenderCellParams} from "@mui/x-data-grid";
import {formatFileSize} from "../fileupload/formatFileSize";
import {enqueueSnackbarNotification} from '../../snackbarNotification/snackbarNotificationSlice';
import CloseNotificationButton from "../../snackbarNotification/CloseNotificationButton";
import {
  isDatasetSelected,
  isFolderIndeterminate,
  isFolderSelected,
  isVisibleFile,
  sortOnName
} from "./datasetpickersUtils";
import {closePopup, showPopup} from '../../popup/popupsSlice';
import {FilePreviewPopup} from "../fileViewer/FilePreviewPopup";
import {ErrorPanel} from "../../ErrorPanel/ErrorPanel";
import {uApi} from "../../biostrandApi/uApi";
import {SearchInput} from "../../searchInput/SearchInput";
import {getDatasets} from "../datasetAPIUtils";

interface DGDatasetFileSelectorProps {
  datasetId: string;
  onSelectionChange: (fileIds: string[]) => void;
  initialSelectedFileIds?: string[];
}

export const DGDatasetFileSelector = (props: DGDatasetFileSelectorProps): JSX.Element => {
  const {datasetId, onSelectionChange, initialSelectedFileIds} = props;
  const currentSelection = initialSelectedFileIds || [];

  const [t] = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [dsFiles, setDsFiles] = useState<DatasetManagerDatasetItem[] | undefined>([]);
  const [visibleFiles, setVisibleFiles] = useState<DatasetManagerDatasetItem[] | undefined>([]);
  const [currentPath, setCurrentPath] = useState<string[]>([]);
  const [dataset, setDataset] = useState<DatasetManagerDatasetItem[] | undefined>();

  const [currentPathFiles, setCurrentPathFiles] = useState<DatasetManagerDatasetItem[] | undefined>([]);
  const dispatch = useDispatch();

  const columns = [
    {
      field: 'cb',
      headerName: ' ',
      width: 30,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (<Checkbox
          checked={isDatasetSelected(datasetId, currentSelection) || isFolderSelected(params.row.name, visibleFiles, currentSelection)}
          indeterminate={isFolderIndeterminate(params.row.name, visibleFiles, currentSelection)}
          onChange={onFolderToggle(params.row.name)}
        />);
      },
    },
    {
      field: 'name',
      headerName: 'name',
      minWidth: 170,
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        const item = params?.row
        const name = item.name || '';
        const labels = name.split('/');
        const label =
          item.object_type === SharedObjectType.FOLDER ? labels[labels.length - 2] : labels[labels.length - 1];

        if (item.object_type === SharedObjectType.FOLDER) {
          return (
            <Stack
              sx={{alignItems: "center", flex: 1}}
              direction={"row"}
              onClick={() => {
                openFolder(name);
              }}
            >
              <Folder color={'primary'}/>
              <Typography variant={"body2"}
                          sx={{flex: 1, textOverflow: "ellipsis", ml: 1, overflow: "hidden", whiteSpace: "nowrap"}}
                          title={label}>{label}</Typography>
            </Stack>
          );
        }

        return (
          <Typography variant={"body2"}
                      sx={{flex: 1, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}
                      title={label}>{label}</Typography>
        );
      },
      sortable: true,
    },
    {
      field: 'size',
      headerName: ' ',
      width: 70,
      align: 'right',
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row.object_type === SharedObjectType.FOLDER) {
          return "";
        }
        return (
          <Typography variant={'body2'}>{formatFileSize(parseInt(params.row.size || '0'))}</Typography>
        );
      },
      sortable: true,
    },
    {
      field: 'action',
      headerName: ' ',
      width: 40,
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row.object_type === SharedObjectType.FOLDER) {
          return <IconButton edge="end" aria-label="open" onClick={() => openFolder(params?.row.name)}>
            <ChevronRight/>
          </IconButton>
        }
        return (
          <IconButton edge="end" aria-label="preview" onClick={() => onFilePreview(params?.row)}>
            <Visibility/>
          </IconButton>

        );
      },
      sortable: false,
    }
  ]

  useEffect(() => {
    if (currentSelection) {
      const loadDataset = async () => {
        const ds = await getDatasets([datasetId])
        setDataset(ds[0]);
      }
      loadDataset();
    }
  }, [datasetId])

  useEffect(() => {
    const runQ = async () => {
      try {
        setIsLoading(true);
        setError(undefined)
        const result = await uApi.getDatasetManagerApi().datasetManagerGetDatasetFiles(datasetId, 10000);
        const fls = result?.data?.data ? result?.data?.data.map(f => {
          return {...f, name: `${datasetId}${f.name}`}
        }) : [];
        const onlyVisible = fls.filter(isVisibleFile)
        setDsFiles(result?.data?.data);
        setVisibleFiles(onlyVisible);
        setIsLoading(false);
        setCurrentPath([]);
      } catch (e) {
        setIsLoading(false);
        setError('file fetch error')
        dispatch(
          enqueueSnackbarNotification({
            message: t('Fetch file list error'),
            key: 'file-list-error',
            options: {
              variant: 'error',
              persist: true,
              action: key => <CloseNotificationButton
                notificationKey={key}>Close</CloseNotificationButton>,
            },
          }),
        );

      }
    };

    runQ();
  }, [datasetId]);

  useEffect(() => {
    const folderPath = datasetId + '/' + currentPath.join('/');
    const folders = (visibleFiles || []).filter(item => {
      if (item.name) {
        if (item.object_type === SharedObjectType.FOLDER)
          return (
            item.name.indexOf(folderPath) === 0 &&
            item.name.indexOf('/', folderPath.length + 1) === item.name.length - 1
          );
      }

      return false;
    });

    const files = (visibleFiles || []).filter(item => {
      if (item.name) {
        if (item.object_type === SharedObjectType.FILE)
          return item.name.indexOf(folderPath) === 0 && item.name.indexOf('/', folderPath.length + 1) === -1;
      }

      return false;
    })

    setCurrentPathFiles([...folders.sort(sortOnName), ...files.sort(sortOnName)])
  }, [visibleFiles, currentPath, initialSelectedFileIds]);

  const openFolder = (folderPath: string) => {
    const parts = folderPath.split('/');
    parts.shift();
    setCurrentPath(parts.filter(p => p !== ''));
  };

  const onFilePreview = useCallback((fileObj: DatasetManagerDatasetItem) => {
    const parts = fileObj.name.split('/');
    parts.shift();

    dispatch(
      showPopup({
        fullscreen: true,
        key: 'file-preview-popup-key',
        title: (
          <Stack direction={'row'} sx={{mt: 2, alignItems: 'center'}} spacing={4}>
            <IconButton
              onClick={() => {
                dispatch(closePopup('file-preview-popup-key'));
              }}
            >
              <ArrowBack/>
            </IconButton>
            {t('Preview')}
          </Stack>
        ),
        content: React.createElement(FilePreviewPopup, {
          popupKey: 'file-preview-popup-key',
          datasetId: datasetId,
          filePath: parts.join('/') || '',
          fileList: dsFiles
        }),
      }),
    );
  }, [dsFiles]);

  const onFolderToggle = useCallback((fullFolderName: string) => () => {
    let newSelection = currentSelection;
    const allDatasetFiles = visibleFiles.filter(item => item.object_type !== SharedObjectType.FOLDER)
      .map(item => item.name);

    if (isDatasetSelected(datasetId, currentSelection)) {
      newSelection = newSelection.filter(selectedFile => selectedFile !== datasetId);
      newSelection = [...newSelection, ...allDatasetFiles];
    }

    if (
      isFolderIndeterminate(fullFolderName, visibleFiles, newSelection) ||
      isFolderSelected(fullFolderName, visibleFiles, newSelection)
    ) {
      newSelection = newSelection.filter(selectedFile => selectedFile.indexOf(fullFolderName) === -1);
      onSelectionChange && onSelectionChange(newSelection);
      return;
    }

    const fileToSelect = allDatasetFiles.filter(fileName => fileName.indexOf(fullFolderName) === 0)

    newSelection = [...currentSelection, ...fileToSelect];

    const dsFilesOutOfSelection = newSelection.filter(selectedFile => selectedFile.indexOf(datasetId) === -1);

    if (dsFilesOutOfSelection.length + allDatasetFiles.length === newSelection.length) {
      dsFilesOutOfSelection.push(datasetId);
      newSelection = dsFilesOutOfSelection;
    }

    onSelectionChange && onSelectionChange(newSelection);

  }, [currentSelection, visibleFiles]);

  if (isLoading)
    return (
      <Stack sx={{minHeight: 300, overflowY: 'hidden', minWidth: 300, flex: 1}}>
        <Typography>
          <LinearProgress/>
        </Typography>
      </Stack>
    );

  if (error) {
    return (<Stack sx={{minHeight: 300, overflowY: 'hidden', minWidth: 300, flex: 1}}>
      <Stack sx={{alignItems: 'center', justifyContent: 'flex-start', minWidth: 300, flex: 1}}>
        <ErrorPanel title={'Error fetching dataset files'} subtitle={'Something went wrong'}/>
      </Stack>

    </Stack>)
  }

  if (!visibleFiles || visibleFiles.length === 0)
    return (
      <Stack sx={{alignItems: 'center', justifyContent: 'flex-start', minWidth: 300, flex: 1}}>
        <ErrorPanel title={'Dataset is empty'}
                    subtitle={'There are different possible reasons for that: the dataset is not uploaded or processed, or some error happened'}/>
      </Stack>
    );

  return (
    <Stack direction={'column'} sx={{minHeight: 300, overflow: "hidden", flex: 1, minWidth: 300}} spacing={1}>
      <SearchInput onChange={() => {
      }} ariaLabel={'Search dataset'} sx={{maxWidth: 300}}/>
      <Breadcrumbs separator={<ChevronRight sx={{m: -1}} fontSize={'small'}/>}>
        {currentPath.length > 0 ? (
          <Link
            key={'root'}
            sx={{cursor: 'pointer'}}
            onClick={() => {
              openFolder('/');
            }}
          >
            {dataset?.name}
          </Link>
        ) : (
          <Typography color="#000000" key={'root'}>
            {dataset?.name}
          </Typography>
        )}

        {currentPath.map((path, index, array) => {
          const fullPath = '/' + array.slice(0, index + 1).join('/') + '/';
          if (index === array.length - 1)
            return (
              <Typography color="#000000" key={fullPath}>
                {path}
              </Typography>
            );
          return (
            <Link
              key={fullPath}
              sx={{cursor: 'pointer'}}
              onClick={() => {
                openFolder(fullPath);
              }}
            >
              {path}
            </Link>
          );
        })}
      </Breadcrumbs>

      <Stack sx={{minHeight: 300, flex: 1}}>
        {currentPathFiles && <DataGrid
          sx={{
            '.MuiDataGrid-root': {border: '#f00 5px'},
            //  position: 'absolute',
            border: '5px',
            // top: 32,
            // bottom: 0,
            // left: -8,
            // right: -8,x
            flex: 1,
          }}
          initialState={{
            sorting: {
              //  sortModel: initialSort,
            },
          }}
          disableSelectionOnClick
          rows={currentPathFiles}
          loading={false}
          columns={columns}
          getRowId={row => row.name || ''}
          disableColumnFilter
          hideFooterRowCount
          hideFooterSelectedRowCount
          keepNonExistentRowsSelected
          selectionModel={currentSelection}
          density={'compact'}
        />}
      </Stack>

      {currentPathFiles && currentPathFiles.length > 0 ? (
        ""
      ) : (
        <Stack sx={{minHeight: 300, alignItems: 'center', justifyContent: 'center', minWidth: 300}}>
          <Typography>Empty folder</Typography>
        </Stack>
      )}
    </Stack>
  );
};
