import { UserManagerUserEntity } from '@biostrand/biostrandapi/javascript/dist/UserManagerApi';
import { Avatar, Button, Chip, List, ListItem, Paper, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getSettingsPagesLink } from '../../../applications/applicationUtils';
import { SettingsIds } from '../../../applications/SettingsIds';
import { navigateTo } from '../../../portalLink/routeUtilSagas';
import { logoutRequest } from '../../../slices/auth/authSlice';
import { currentOrganizationSelector, currentUserSelector } from '../../../slices/user/userSelectors';
import { updateUserPreferredOrganizationAction } from '../../../slices/user/userSlice';

interface Props {
  user: UserManagerUserEntity;
}

export const AvatarMenuList = (props: Props): JSX.Element => {
  const { user } = props;
  const userFace = useSelector(currentUserSelector);
  const currentOrg = useSelector(currentOrganizationSelector);
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const switchOrganization = async (organization_id: string | undefined) => {
    if (organization_id) {
      try {
        await confirm({
          title: t('Updating preferred organization'),
          description: t(`Are you sure you want to update your preferred organization?`),
        });
        dispatch(updateUserPreferredOrganizationAction({ organization_id: organization_id }));
      } catch (e) {
        console.log(e);
      }
    }
  };

  const openOrganizationSettings = () => {
    dispatch(navigateTo({ link: getSettingsPagesLink(SettingsIds.ORGANIZATION) }));
  };

  const openUserSettings = () => {
    dispatch(navigateTo({ link: getSettingsPagesLink(SettingsIds.PERSONAL) }));
  };

  return (
    <Paper sx={{ maxWidth: '100%', width: 300 }}>
      <Stack>
        <Stack spacing={2} sx={{ alignItems: 'center', p: 2, pt: 4, borderBottom: 'solid 1px #EEE' }}>
          <Avatar sx={{ width: 72, height: 72 }} alt={user.email}>
            {user.email ? user.email.substring(0, 2).toUpperCase() : '?'}
          </Avatar>
          <Typography variant="body2">{user.email}</Typography>
          {/*<Typography variant="caption">{user.email}</Typography>*/}
          <Stack direction={'row'} spacing={2}>
            <Button variant={'outlined'} onClick={openUserSettings}>
              {t('Settings')}
            </Button>
            <Button
              variant={'outlined'}
              onClick={() => {
                dispatch(logoutRequest());
              }}
            >
              {t('Logout')}
            </Button>
          </Stack>
        </Stack>
        <RadioGroup defaultValue={currentOrg?.organization_id}>
          <List>
            {userFace
              ? userFace.organizations?.map(org => {
                  return (
                    <ListItem
                      onClick={() => switchOrganization(org.organization_id)}
                      sx={{ alignItems: 'flex-start' }}
                      key={org.organization_id}
                      dense
                      selected={org.organization_id === currentOrg?.organization_id}
                    >
                      <Radio value={org.organization_id} />
                      <Stack sx={{ p: 1, pt: 1.4 }}>
                        <Typography
                          variant={'subtitle2'}
                          color={org.organization_id === currentOrg?.organization_id ? 'primary' : 'primary.contrast'}
                        >
                          {org.organization_name}
                        </Typography>
                        <Chip size={'small'} label={org.organization_role?.toLowerCase()} />
                      </Stack>
                    </ListItem>
                  );
                })
              : null}
          </List>
        </RadioGroup>
        {currentOrg?.organization_role === 'ADMIN' ? (
          <Stack sx={{ alignItems: 'center', p: 2, pt: 2, borderTop: 'solid 1px #EEE' }}>
            <Button variant={'outlined'} onClick={openOrganizationSettings}>
              {t('Manage Organization')}
            </Button>
          </Stack>
        ) : null}
      </Stack>
    </Paper>
  );
};
