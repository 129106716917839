import { UserManagerGetAuthorizationContextResponse } from '@biostrand/biostrandapi/javascript/dist/UserManagerApi';
import {
  AuthenticationStatus,
  bootstrapAppAction,
  getIsAuthenticated,
  handleLogout,
  setCurrentApp,
  showError,
  uApi,
  updateAuthStatus,
  updateUserContext,
} from '@biostrand/components';
import { updateUserFace } from '@biostrand/components/src/slices/user/userSlice';
import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

export function* bootstrapAppUser(action: ActionType<typeof bootstrapAppAction>) {
  const currentApp = action.payload;
  yield put(setCurrentApp(currentApp));
  try {
    const isAuthenticated = yield call(getIsAuthenticated);
    if (!isAuthenticated) {
      yield put(updateAuthStatus(AuthenticationStatus.NOT_AUTHENTICATED));
      yield handleLogout();
      return;
    }
  } catch (err) {
    yield put(updateAuthStatus(AuthenticationStatus.NOT_AUTHENTICATED));
    yield handleLogout();
    return;
  }

  const user = yield initUser();

  if (!user) {
    yield put(updateAuthStatus(AuthenticationStatus.NOT_AUTHENTICATED));
    yield showError('Failed to get user details');
    yield handleLogout();
    return;
  }
  yield put(updateAuthStatus(AuthenticationStatus.AUTHENTICATED));
}

export function* initUser() {
  try {
    const result = yield call([uApi.getUserManagerApi(), 'userManagerGetAuthorizationContext']);
    const user: UserManagerGetAuthorizationContextResponse = result.data.data;
    yield put(updateUserContext(user));

    const result2 = yield call([uApi.getUserManagerApi(), 'userManagerGetUser']);
    const user2 = result2.data;

    yield put(updateUserFace(user2));
    return user;
  } catch (e) {
    console.log(e);
  }
}
