import {BuiltInTrajectoryFormat} from "molstar/lib/mol-plugin-state/formats/trajectory";

export const isSupportedByMolstar = (ext: string) => {
    if (ext) {
        switch (ext.toLowerCase()) {
            case 'pdb':
            case 'gro':
            case 'mol':
            case 'mol2':
            case 'ent':
            case 'pdbqt':
            case 'sdf':
            case 'sd':
            case 'xyz':
            case 'ccp4':
            case 'mrc':
            case 'map':
            case 'cub':
            case 'cube':
            case 'dsn6':
            case 'brix':
            case 'dx':
            case 'dxbin':
            case 'cif':
            case 'mmcif':
            case 'cimciff':
            case 'bcif':
                return true;
        }
    }
    return false;
}

export const getMolstarContentType = (ext: string) => {
    if (ext) {
        switch (ext.toLowerCase()) {
            case 'cif':
            case 'mmcif':
            case 'cimciff':
            case 'bcif':
                return 'mmcif';
            case 'ent':
                return 'pdb';
            case 'sd':
                return 'sdf';
        }
    }

    return ext.toLowerCase();
}

export const loadStructure = async (data, label: string, contentType: string, plugin, clear) => {
    if (plugin) {
        if (clear) {
            plugin.clear();
        }
        if (data) {
            const rawData = await plugin.builders.data.rawData({
                label,
                data,
            });
            const traj = await plugin.builders.structure.parseTrajectory(rawData, contentType as BuiltInTrajectoryFormat);
            await plugin.builders.structure.hierarchy.applyPreset(traj, 'default');
        }
    }
}

export interface PyMOLColorCommand {
    color: string;
    selection?: string;
}

export const parsePyMOLColorCommand = (command: string): PyMOLCommand | null => {
    const regex = /^color\s+((?:#|0x)?[\w-]+)(?:[,\s]+(.+))?$/i;
    const match = command.match(regex);
    if (match) {
        return  {command: 'color', args: match[2] ? [match[1], match[2]]: [match[1]]}
    }
    return null;
};

export interface PyMOLContext {
    [selectionKey:string]: string; // usually select command;
}

export interface PyMOLCommand {
    command: string;
    args: string[];
    options?: string[]
}

export const parsePyMOLCommands = (input) => {
    const commands = input.split(/\n|;+/).map(cmd => cmd.trim()).filter(cmd => cmd);
    const parsedCommands = [];
    for (const commandStr of commands) {
        const match = commandStr.match(/^(\w+)\s+(.+)$/);
        if (!match) continue;

        const command = match[1];
        const argsStr = match[2];
        const args = [];
        const options = {};

        let buffer = "";
        let depth = 0;

        for (let i = 0; i < argsStr.length; i++) {
            const char = argsStr[i];
            if (char === '(') depth++;
            if (char === ')') depth--;
            if (char === ',' && depth === 0) {
                args.push(buffer.trim());
                buffer = "";
                continue;
            }
            buffer += char;
        }
        if (buffer) args.push(buffer.trim());
        parsedCommands.push( {command, args, options});
    }
    return parsedCommands;
}
