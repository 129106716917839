import {closePopup} from '@biostrand/components/src/popup/popupsSlice';
import {Button, IconButton, Typography} from '@mui/material';
import {Stack} from '@mui/system';
import * as React from 'react';
import {useDispatch} from 'react-redux';
import {useWindowDimensions} from "@biostrand/components/src/utilites/useWindowDimensions";
import {ContentCopy, Edit} from "@mui/icons-material";
import dayjs from "dayjs";


interface MolstarChatQueryHistoryPopupProps {
  history: [],
  popupKey: string,
  onItemHistory: (item:any) => {}
}

export const MolstarChatQueryHistoryPopup = (props: MolstarChatQueryHistoryPopupProps): JSX.Element => {
    const {history, popupKey, onItemHistory} = props;
    const dispatch = useDispatch();
    const {width = 600, height = 600} = useWindowDimensions()


    const onClose = () => {
        dispatch(closePopup(popupKey));
    };

    return (
        <Stack sx={{flex: 1, overflow: 'hidden', minWidth: 600, minHeight: height - 120}} direction={'column'}>

          <Stack spacing={2} sx={{flex:1, overflowY:'scroll'}}>
            {history.map(historyItem => <Stack direction={"column"} key={historyItem.date.toString()} spacing={0.5}>
              <Stack direction={'row'} spacing={1} sx={{alignItems:'center'}}>
                <Typography variant={"caption"}>{dayjs(historyItem.date).format('hh:mm:ss')}</Typography>
                <Typography variant={"body1"}
                sx={{p:1, pt:0.5, pb:0.5,
                  backgroundColor: '#efefff', borderRadius:1
                }}>{historyItem.request}</Typography>
                <IconButton size={"small"} onClick={()=>{
                  onItemHistory(historyItem.request);
                  onClose();
                }}><Edit fontSize={"small"} /></IconButton>
                <IconButton size={"small"}><ContentCopy fontSize={"small"} /></IconButton>
              </Stack>
              <pre
                style={{
                  maxWidth: 800,
                  maxHeight: 300,
                  overflow: "scroll",
                  alignSelf: "flex-end",
                  fontFamily: 'monospace',
                  fontSize: 14,
                  padding: 4,
                  borderRadius: 4,
                  backgroundColor: '#f0f0f0',
                  cursor: "pointer"
                }}
              >{historyItem.response}</pre>
            </Stack>)}
          </Stack>
            <Stack sx={{pt: 2}} direction='row' justifyContent='flex-end' spacing={2}>
                <Button
                    onClick={() => {
                        onClose();
                    }}
                    variant='outlined'
                    color='primary'>
                    Close
                </Button>
            </Stack>
        </Stack>
    );
};
