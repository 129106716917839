import { refreshSession, showError, uApi } from '@biostrand/components';
import { showSuccess } from '@biostrand/components/src/snackbarNotification/snackbarNotificationSagaHelpers';
import { all, call, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { updateUserPreferredOrganizationAction, UPDATE_USER_PREFERRED_ORGANIZATION } from './userSlice';

function* handleUpdateUserPreferredOrganization(action: ActionType<typeof updateUserPreferredOrganizationAction>) {
  const organization_id = action.payload;

  try {
    const result = yield call([uApi.getUserManagerApi(), 'userManagerUpdatePreferredOrganization'], organization_id);

    if (result) {
      yield showSuccess('Preferred organization updated');
      refreshSession().then(() => window.location.reload());
    }
  } catch (err) {
    yield showError(`Something went wrong`, err.toString());
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* commonUsersSaga() {
  yield all([yield takeEvery(UPDATE_USER_PREFERRED_ORGANIZATION, handleUpdateUserPreferredOrganization)]);
}
