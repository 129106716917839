import {DatasetManagerDatasetEntity} from '@biostrand/biostrandapi/javascript/dist/DatasetManagerApi';
import {ExpandMore} from '@mui/icons-material';
import {ButtonBase, Skeleton, Stack, Typography, useTheme} from '@mui/material';
import {useField} from 'formik';
import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {SelectDatasetOrFilePopup} from "./SelectDatasetOrFilePopup";
import {getDatasets} from "../datasetAPIUtils";

interface DatasetOrFilePlaceholderProps {
  error: string | undefined;
  currentPath: string;
  placeholder: string;
}


const DatasetOrFilePlaceholder = (props: DatasetOrFilePlaceholderProps) => {
  const {error, currentPath, placeholder} = props;
  const theme = useTheme();
  const [isDatasetsLoading, setIsDatasetLoading] = useState(false);
  const [path, setPath] = useState<string | undefined>();
  const [selectedItem, setSelectedItem] = useState<string | undefined>();
  const [currentDatasets, setCurrentDatasets] = useState<DatasetManagerDatasetEntity[] | undefined>();

  useEffect(() => {
    const loadDataset = async () => {
      try {
        setIsDatasetLoading(true)

        if (currentPath && currentPath.length > 0) {
          const parts = currentPath.split('/');
          if (parts.length === 0) {
            setPath(undefined);
            setSelectedItem(undefined);
            return;
          }
          const dsId = parts.shift() || '';
          const dsResult = await getDatasets([dsId]);
          setCurrentDatasets(dsResult);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsDatasetLoading(false);
      }
    };
    loadDataset();
  }, [currentPath]);

  useEffect(() => {
    if (currentPath && currentPath.length > 0 && currentDatasets) {
      const parts = currentPath.split('/');

      if (parts.length === 0) {
        setPath(undefined);
        setSelectedItem(undefined);
        return;
      }
      const dsId = parts.shift() || '';
      const dataset = currentDatasets?.find(ds => ds.id === dsId);

      if (parts.length > 0) {
        // file selection scenario
        const fileName = parts.pop();
        parts.unshift(dataset?.name || '');

        setSelectedItem(fileName);
        setPath(parts.join('/'));
      } else {
        setSelectedItem(dataset?.name);
        setPath(undefined);
      }
    }
  }, [currentDatasets, currentPath]);

  return (
    <Stack
      direction={'row'}
      sx={{
        flex: 1,
        border: `1px solid ${error ? theme.palette.error.main : theme.palette.grey['400']}`,
        borderRadius: '4px',
        alignItems: 'flex-start',
        textAlign: 'left',
        justifyItems: 'flex-start',
        p: 1,
        pl: 2,
      }}
    >
      {isDatasetsLoading ? (
        <Skeleton animation="wave" height={24} sx={{flex: 1}}/>
      ) : (
        <>
          {currentPath ? (
            <Stack direction={'column'} sx={{flex: 1}}>
              {path ? <Typography variant={'caption'}>{path}</Typography> : null}
              {selectedItem ? <Typography variant={'subtitle2'}>{selectedItem}</Typography> : null}
            </Stack>
          ) : (
            <Typography sx={{flex: 1}}>{placeholder ? placeholder : "Select dataset or file"}</Typography>
          )}
          <ExpandMore/>
        </>
      )}
    </Stack>
  );
};

export const SingleDatasetOrFileSelectionField = ({...props}) => {
  const {helperText, name, error, placeholder} = props;
  const [, meta, helpers] = useField(name);

  const {value} = meta;
  const {setValue, setTouched} = helpers;

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  const onDatasetOrFileSelectionChange = useCallback(sds => {
    console.log(" onDatasetOrFileSelectionChange => ", sds)
    setValue(sds);
    onClose();
  }, []);

  const onShowDatasetSelectionPopup = () => {
    setTouched(true);
    setIsPopupOpen(true);
  };

  const onClose = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      <ButtonBase onClick={onShowDatasetSelectionPopup}>
        {/*<DGSelectedItemsPlaceholder  error={error} selectedItemsIds={value} />*/}
        <DatasetOrFilePlaceholder error={error} currentPath={value && value[0] ? value[0] : ''}
                                  placeholder={placeholder}/>
      </ButtonBase>
      {error ? (
        <Typography variant={'caption'} sx={{pl: 2}} color={'error'}>
          {error}
        </Typography>
      ) : (
        <Typography variant={'caption'} sx={{pl: 2}}>
          {helperText}
        </Typography>
      )}

      {/*<MultiDsAndFilesSelect popupKey={'select-dataset-popup-key'}*/}
      {/*                       open={isPopupOpen}*/}
      {/*                       onCancel={onClose}*/}
      {/*                       onDatasetOrFileSelectionChange={onDatasetOrFileSelectionChange} />*/}
      <SelectDatasetOrFilePopup
        popupKey={'select-dataset-1-popup-key'}
        open={isPopupOpen}
        onCancel={onClose}
        onDatasetOrFileSelectionChange={onDatasetOrFileSelectionChange}
      />
      {/*<SelectDatasetOrFilePopup*/}
      {/*  popupKey={'select-dataset-popup-key'}*/}
      {/*  open={isPopupOpen}*/}
      {/*  onCancel={onClose}*/}
      {/*  onDatasetOrFileSelectionChange={onDatasetOrFileSelectionChange}*/}
      {/*/>*/}
    </>
  );
};
