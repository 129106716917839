import {
  CheckCircleOutline,
  DeleteForever,
  ErrorOutline,
  InfoOutlined,
  QueryBuilderOutlined,
  Refresh,
  WarningAmber,
  WarningOutlined
} from '@mui/icons-material';
import {Alert, AlertTitle, Button, IconButton, Tooltip, Typography} from '@mui/material';
import {Stack} from '@mui/system';
import {DataGrid, GridColDef, GridRenderCellParams, useGridApiRef} from '@mui/x-data-grid';
import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {fumDatasetFilesSelector, hasPartError} from '../slices/datasets/fileUploadSelectors';
import {AppWithFileUploadManagerState, FileResourcePayload, FileUploadInfo} from '../slices/datasets/fileUploadTypes';
import {ReselectFileButton} from "./fileupload/ReselectFileButton";
import {formatFileSize} from "./fileupload/formatFileSize";
import {
  assignMultipleFileResource,
  cancelFileUploading,
  clearAllPartUploadErrors,
  clearPartUploadError,
  finalizeDatasetAction,
  kickUploadProcess,
  uploadManagerSaveState
} from '../slices/datasets/fileUploadManagerSlice';
import {FileProgressCell} from "./fileupload/FileProgressCell";
import {DSStatuses, getFileStatusSummary, getStatusSummary,} from "../slices/datasets/fileUploadUtils";
import {FilesUploadArea} from "./FilesUploadArea";
import {matchFileName} from "./fileupload/fileUtils";
import {FilesSelectButton} from "./FilesSelectButton";

import {
  DatasetManagerDatasetEntity,
  DatasetManagerDatasetStatusCode
} from "@biostrand/biostrandapi/javascript/dist/DatasetManagerApi";
import {deleteDatasetsAction} from "../slices/datasets/datasetsSlice";
import {enqueueSnackbarNotification} from "../snackbarNotification/snackbarNotificationSlice";
import {CloseNotificationButton} from "../index";
import {useConfirm} from "material-ui-confirm";
import {SettingsIds} from "../applications/SettingsIds";
import {push} from "connected-react-router";
import {sleep} from "../utilites/sleep";
import {getDatasets} from "./datasetAPIUtils";

interface DatasetUploadProgressProps {
  datasetId: string
}

const dsIdWrap = (datasetId: string) => (state: AppWithFileUploadManagerState) => fumDatasetFilesSelector(state, datasetId)

const DEFAULT_FILES_TO_IGNORE = [
  '.DS_Store', // OSX indexing file
  'Thumbs.db'  // Windows indexing file
];

function shouldIgnoreFile(file: File) {
  return DEFAULT_FILES_TO_IGNORE.indexOf(file.name) >= 0;
}

export const DatasetUploadProgress = (props: DatasetUploadProgressProps): JSX.Element => {
  const {datasetId} = props;
  const currentFiles: FileUploadInfo[] = useSelector(dsIdWrap(datasetId));
  const [dataset, setDataset] = useState<DatasetManagerDatasetEntity | undefined>();
  const dispatch = useDispatch();
  const gridApiRef = useGridApiRef();
  const confirm = useConfirm();
  const [statusSummary, setStatusSummary] = useState()


  useEffect(() => {
    if (datasetId) {
      const loadDataset = async () => {
        const ds = await getDatasets([datasetId])
        setDataset(ds[0]);
      }
      loadDataset();
    }
  }, [datasetId])

  useEffect(() => {
    if (currentFiles) {
      setStatusSummary(getStatusSummary(currentFiles));
    }
  }, [currentFiles])

  const handleDelete = async (ds: DatasetManagerDatasetEntity) => {
    try {
      if (ds.name) {
        await confirm({
          title: 'Delete dataset',
          description: `The uploaded files and ${ds.name} dataset will be deleted permanently.`
        });
        dispatch(deleteDatasetsAction(ds.id || ''));
        await sleep(1500);
        dispatch(push(`/settings/${SettingsIds.DATASETS}`))
      }
    } catch (e) {
      if (e) {
        dispatch(
          enqueueSnackbarNotification({
            message: 'Delete dataset',
            key: 'delete-error',
            options: {
              variant: 'error',
              persist: true,
              action: key => (
                <CloseNotificationButton notificationKey={key}>Close</CloseNotificationButton>
              ),
            },
          }),
        );
      }
    } finally {
    }
  };

  const onFileSelection = useCallback((acceptedFiles) => {
    const fileList = acceptedFiles.value ? [...acceptedFiles.value] : [];
    const missedResources: FileResourcePayload[] = [];

    for (let i = 0; i < acceptedFiles.length; i++) {

      const file = acceptedFiles[i]
      if (!shouldIgnoreFile(file)) {
        fileList.push(file);
        if (statusSummary && statusSummary[DSStatuses.FILE_MISSED]) {
          const missedFile = statusSummary[DSStatuses.FILE_MISSED].find((dsFile: FileUploadInfo) => {
            return matchFileName(dsFile.fileName, file.path)
          })
          if (missedFile) {
            missedResources.push({fileId: missedFile.fileId, file: file});
          }
        }
      }
    }
    if (missedResources && missedResources.length > 0) {
      dispatch(assignMultipleFileResource(missedResources));
      dispatch(kickUploadProcess());
      dispatch(uploadManagerSaveState())
    }
  }, [statusSummary, currentFiles])
  const filesColumns: GridColDef[] = [
    {
      field: 'fileName',
      headerName: 'File name',
      valueGetter: (params) => {
        return params.row.fileName.split('/').pop();
      },
      flex: 1,
      sortable: true,
      minWidth: 130,
    },
    {
      field: 'path',
      headerName: 'Path',
      valueGetter: (params) => {
        const parts = params.row.fileName.split('/');
        parts.pop();
        return '/' + parts.join('/');
      },
      flex: 1,
      sortable: true,
      minWidth: 130,
    },
    {
      field: 'fileSize',
      headerName: 'Size',
      width: 100,
      align: 'right',
      valueGetter: (row) => formatFileSize(row.value)
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 130,
      valueGetter: (params) => {
        const fileInfo: FileUploadInfo = params.row;
        const statusSummary = getFileStatusSummary(fileInfo)
        if (statusSummary.isReady) return 0;
        if (statusSummary.isDraft) return 1;
        if (statusSummary.isUploading) return 2;
        if (statusSummary.isFileMissed) return 3;
        if (statusSummary.hasError) return 4;
        return -1;

      },
      renderCell: (params: GridRenderCellParams<string>): JSX.Element | null => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const dispatch = useDispatch();
        const fileInfo: FileUploadInfo = params.row;
        const statusSummary = getFileStatusSummary(fileInfo)

        if (statusSummary.isReady) {
          return (
            <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
              <CheckCircleOutline color={'success'}/>
              <Typography variant={'subtitle2'}>Uploaded</Typography>{' '}
            </Stack>
          );
        }

        if (statusSummary.hasError) {
          return (<Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
            <WarningOutlined color={'error'}/>
            <Typography variant={'subtitle2'}>Error</Typography>{' '}
          </Stack>)
        }

        if (statusSummary.isFileMissed) {
          return (
            <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
              <ErrorOutline color={'warning'}/>
              <Typography variant={'subtitle2'}>Missing file</Typography>{' '}
            </Stack>
          );
        }

        if (statusSummary.isDraft) {
          return <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
            <QueryBuilderOutlined/>
            <Typography variant={'subtitle2'}>queued</Typography>
          </Stack>
        }

        if (statusSummary.isUploading) {
          return <FileProgressCell fileInfo={fileInfo} sx={{minWidth: 300}}/>
        }

        if (hasPartError(fileInfo)) {
          return (
            <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
              <WarningAmber color={'warning'}/>
              <Typography variant={'subtitle2'}>Upload error</Typography>{' '}
              <IconButton size={"small"} onClick={() => {
                dispatch(clearPartUploadError(fileInfo.fileId));
                dispatch(kickUploadProcess());
                dispatch(uploadManagerSaveState())
              }}><Refresh/></IconButton>
            </Stack>
          );
        }
        return <div/>;
      },
    },
    {
      field: 'action',
      headerName: '',
      width: 100,
      align: 'right',
      renderCell: (params: GridRenderCellParams<string>): JSX.Element | null => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const dispatch = useDispatch();
        const fileInfo: FileUploadInfo = params.row;
        const statusSummary = getFileStatusSummary(fileInfo)

        if (statusSummary.hasError) {
          return (<Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
            <IconButton size={"small"} onClick={(e) => {
              e.preventDefault();
              dispatch(clearPartUploadError(fileInfo.fileId));
              dispatch(kickUploadProcess());
              dispatch(uploadManagerSaveState())
            }}><Refresh/></IconButton>
            <IconButton size={"small"} onClick={(e) => {
              e.preventDefault();
              dispatch(cancelFileUploading(fileInfo.fileId));
              dispatch(uploadManagerSaveState())
            }}><DeleteForever/></IconButton>
          </Stack>)
        }
        if (statusSummary.isFileMissed) {
          return (
            <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
              <ReselectFileButton fileInfo={fileInfo}/>
              <IconButton size={"small"} onClick={(e) => {
                e.preventDefault();
                dispatch(cancelFileUploading(fileInfo.fileId));
                dispatch(uploadManagerSaveState())
              }}><DeleteForever/></IconButton>
            </Stack>
          );
        }
        return <div/>
      },
    },
  ];

  return (
    <FilesUploadArea onFileSelection={onFileSelection}>
      <Stack
        sx={{pb: 1, flex: 1, mr: 3, position: 'relative', width: "100%"}}
        spacing={2}
        direction={"column"}>
        {dataset?.status !== DatasetManagerDatasetStatusCode.COMPLETE ?
          <Stack direction={"column"} spacing={1} >
        <Stack direction={"row"} spacing={2} sx={{alignItems: "baseline"}}>
          <Button
            variant={"contained"}
            onClick={() => {
              dispatch(finalizeDatasetAction(datasetId));
            }}>Finalize dataset</Button>

          <Typography variant={"body1"}>Before use dataset in the jobs the dataset should be finalized.
            <Tooltip
              title={"The dataset should be finalized before being used with the jobs. After that, it will be impossible to add new files.\nAll files that are not in the uploaded state will be removed"}>
              <IconButton><InfoOutlined/></IconButton>
            </Tooltip>
          </Typography>
        </Stack>
          <Stack direction={"row"} spacing={2} sx={{alignItems: "baseline"}}>
          <Button
            variant={"outlined"}
            onClick={async () => {
              await handleDelete(dataset);
            }}>Delete dataset</Button>
          <Typography variant={"body1"}>Cancel file upload, remove uploaded files and dataset itself
              {/*<IconButton><InfoOutlined/></IconButton>*/}
          </Typography>

        </Stack></Stack>: null}

        <Stack direction={'column'} spacing={1}>
          {statusSummary && statusSummary[DSStatuses.ERROR].length ?
            <Alert sx={{maxWidth: 1200}} severity={"error"} action={<Stack spacing={1} direction={'row'}>
              <Button color="inherit" size="small" onClick={() => {
                dispatch(clearAllPartUploadErrors(datasetId));
                dispatch(kickUploadProcess());
                dispatch(uploadManagerSaveState())
              }
              }>Refresh all</Button>
              <Button color="inherit" size="small">Remove all</Button>
            </Stack>}>
              <AlertTitle>{statusSummary[DSStatuses.ERROR].length} files in error.</AlertTitle>
              Press refresh to try upload them again or you can remove them from dataset
            </Alert> : null}
          {statusSummary && statusSummary[DSStatuses.FILE_MISSED].length ?
            <Alert sx={{maxWidth: 1200}} severity="warning" action={
              <Stack spacing={1} direction={'row'}>
                <FilesSelectButton color="inherit" size="small" onFileSelection={onFileSelection}>Select
                  files</FilesSelectButton>
                <Button color="inherit" size="small"

                >Remove all</Button>
              </Stack>}>
              <AlertTitle>{statusSummary[DSStatuses.FILE_MISSED].length} files are missed.</AlertTitle>
              You can drop them here or use select button
            </Alert>
            : null}
        </Stack>
        <Stack sx={{flex: 1}}>
          {currentFiles && currentFiles.length ?
            <DataGrid
              sx={{
                mr: 3,
                border: '5px',
                '.MuiDataGrid-root': {border: '#f00 5px'},
              }}
              apiRef={gridApiRef}
              hideFooter
              rows={currentFiles}
              columns={filesColumns}
              getRowId={row => row.fileId}
              disableSelectionOnClick
              keepNonExistentRowsSelected
              rowsPerPageOptions={[50]}
              density={"compact"}
            /> : null}
        </Stack>

      </Stack>
    </FilesUploadArea>
  );
}
