import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {DatasetsState} from './datasetsTypes';
import {CreateS3DatasetValues, UploadDatasetValues} from "../../datasets/datasetTypes";
import {
    DatasetManagerDatasetEntity,
    DatasetManagerRegisterDatasetRequest
} from "@biostrand/biostrandapi/javascript/dist/DatasetManagerApi";

export const REFRESH_DATASETS = '@datasets/REFRESH_DATASETS';
export const UPDATE_DATASET = '@datasets/UPDATE_DATASET';
export const DELETE_DATASET = '@datasets/DELETE_DATASET';

export const CREATE_DATASET_REQUEST = '@datasets/CREATE_DATASET_REQUEST';
export const UPLOAD_DATASET_REQUEST = '@datasets/UPLOAD_DATASET_REQUEST';
export const CREATE_S3_DATASET_REQUEST = '@datasets/CREATE_S3_DATASET_REQUEST';

export const RUN_CREATE_FTP_DATASET_FLOW = '@datasets/RUN_CREATE_FTP_DATASET_FLOW';
export const RUN_CREATE_HTTP_DATASET_FLOW = '@datasets/RUN_CREATE_HTTP_DATASET_FLOW';
export const RUN_UPLOAD_DATASET_FLOW = '@datasets/RUN_UPLOAD_DATASET_FLOW';
export const RUN_CREATE_S3_DATASET_FLOW = '@datasets/RUN_CREATE_S3_DATASET_FLOW';

export type CreateDatasetRequestCallback = (error: string | undefined) => void;

interface CreateDatasetRequest {
    callback: CreateDatasetRequestCallback;
    onValidateError?: CreateDatasetRequestCallback;
    datasetRequest: DatasetManagerRegisterDatasetRequest;
}

type UploadDatasetRequest = CreateDatasetRequest & {
    datasetSettings: UploadDatasetValues,
}

type CreateS3DatasetRequest = CreateDatasetRequest & {
    datasetSettings: CreateS3DatasetValues,
}


interface UpdateDatasetPipelineRequest {
    datasetSettings: DatasetManagerDatasetEntity,
    callback: (error: string | undefined) => void
}

export const refreshDatasetsAction = createAction( REFRESH_DATASETS );
export const updateDatasetsPipelineAction = createAction<UpdateDatasetPipelineRequest>( UPDATE_DATASET );
export const deleteDatasetsAction = createAction<string>( DELETE_DATASET );

export const createDatasetRequestAction = createAction<CreateDatasetRequest>( CREATE_DATASET_REQUEST );

export const uploadDatasetRequestAction = createAction<UploadDatasetRequest>( UPLOAD_DATASET_REQUEST );
export const createS3DatasetRequestAction = createAction<CreateS3DatasetRequest>( CREATE_S3_DATASET_REQUEST );

export const runCreateFtpDatasetFlowAction = createAction( RUN_CREATE_FTP_DATASET_FLOW );
export const runCreateHttpDatasetFlowAction = createAction( RUN_CREATE_HTTP_DATASET_FLOW );
export const runUploadDatasetFlowAction = createAction( RUN_UPLOAD_DATASET_FLOW );
export const runCreateS3DatasetFlowAction = createAction( RUN_CREATE_S3_DATASET_FLOW );

const initialState: DatasetsState = {
    datasets: undefined,
    isLoading: false,
    datasetRefreshCounter: 0
};

export const datasetsSlice = createSlice({
    name: 'datasets',
    initialState,
    reducers: {
        updateDatasets: (state, action: PayloadAction<DatasetManagerDatasetEntity[]>) => {
            state.datasets = action.payload;
        },
        setDatasetsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        updateDatasetRefreshCounter: (state, action: PayloadAction<number> ) => {
            state.datasetRefreshCounter = action.payload;
        }
    },
});

export const { updateDatasets, updateDatasetRefreshCounter, setDatasetsLoading } = datasetsSlice.actions;

export default datasetsSlice.reducer;

