import {
  UserManagerUserEntity,
  UserManagerUserOrganizationEntity,
} from '@biostrand/biostrandapi/javascript/dist/UserManagerApi';
import { Application, Apps, APP_LIST, RuntimeConfig } from '@biostrand/components';
import { AppWithUserContextState } from './userTypes';

export const currentUserSelector = (state: AppWithUserContextState): UserManagerUserEntity | undefined =>
  state.user.userFace?.user;

export const currentOrganizationSelector = (
  state: AppWithUserContextState,
): UserManagerUserOrganizationEntity | undefined => {
  return state.user.userFace?.user?.organizations?.find(
    org => org.organization_id == state.user.userFace?.current_organization_id,
  );
};

export const currentOrganizationNameSelector = (state: AppWithUserContextState): string | undefined => {
  //FIXME the new responses doesn't contain organisation information
  return 'orgName ' + state.user.userFace?.current_organization_id;
};

export const userSelector = (state: AppWithUserContextState): UserManagerUserEntity | undefined =>
  state.user.context?.user;
export const organizationSelector = (state: AppWithUserContextState) => state.user.context?.organization;
export const currentAppIDSelector = (state: AppWithUserContextState): Apps | undefined => state.user.currentApp;

export const userApplicationsSelector = (state: AppWithUserContextState): Application[] => {
  const user = currentUserSelector(state);
  const userApps = [...(user?.applications || [])];

  // todo: should every user have access to portal by default or can it be hardcoded like that ?
  if (!userApps.find(app => app === Apps.PORTAL)) userApps.unshift(Apps.PORTAL);

  const apps = APP_LIST.filter(a => {
    return a.permanent || userApps.indexOf(a.id) > -1;
  }).map(a => ({ ...a }));
  const applicationLinks: any = RuntimeConfig.getAppLinks();
  apps.forEach(a => {
    const link = applicationLinks[a.id] ? applicationLinks[a.id] : a.url;
    a.url = link;
  });
  return apps;
};

export const userApplicationsExceptCurrentSelector = (state: AppWithUserContextState): Application[] => {
  const currentAppId = currentAppIDSelector(state);
  const apps = userApplicationsSelector(state);

  return apps.filter(app => app.id !== currentAppId);
};

export const userApplicationContextKeySelector = (state: AppWithUserContextState): string => {
  const currentAppId = currentAppIDSelector(state);
  const organization = organizationSelector(state);
  const user = userSelector(state);
  //FIXME: replace user?.email by Id when it will be available
  return `${currentAppId}::${organization?.id}::${user?.email}`;
};
