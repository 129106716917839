import { OrganizationUserEntity } from '@biostrand/components/src/slices/user/userTypes';

export interface UsersState {
    users?: OrganizationUserEntity[];
    isLoading: boolean;
}

export interface AppWithOrganizationUsers {
    organizationUsers: UsersState;
}

export enum UserStatus {
    PENDING = 'PENDING',
    ACTIVE = 'ACTIVE',
    DEACTIVATED = 'DEACTIVATED',
}
