import {
  UserManagerGetAuthorizationContextResponse,
  UserManagerGetUserResponse,
} from '@biostrand/biostrandapi/javascript/dist/UserManagerApi';
import { Apps } from '@biostrand/components';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserContextState } from './userTypes';

export const UPDATE_USER_PREFERRED_ORGANIZATION = '@organisation/users/UPDATE_USER_PREFERRED_ORGANIZATION';

interface UpdateUserPreferredOrganizationRequest {
  organization_id: string;
}

export const updateUserPreferredOrganizationAction = createAction<UpdateUserPreferredOrganizationRequest>(
  UPDATE_USER_PREFERRED_ORGANIZATION,
);

const initialState: UserContextState = {
  currentApp: Apps.PORTAL,
};

export const userSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateUserContext: (state, action: PayloadAction<UserManagerGetAuthorizationContextResponse>) => {
      state.context = action.payload;
    },
    setCurrentApp: (state, action: PayloadAction<Apps>) => {
      state.currentApp = action.payload;
    },
    updateUserFace: (state, action: PayloadAction<UserManagerGetUserResponse>) => {
      state.userFace = action.payload;
    },
  },
});

export const { updateUserContext, setCurrentApp, updateUserFace } = userSlice.actions;

export default userSlice.reducer;
