import {
  UserManagerUserEntity,
  UserManagerUserOrganizationEntity,
} from '@biostrand/biostrandapi/javascript/dist/UserManagerApi';
import { Avatar, Box, Popover, Skeleton, Typography } from '@mui/material';
import { Stack, Theme } from '@mui/system';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { currentOrganizationSelector, currentUserSelector } from '../../../slices/user/userSelectors';
import { AvatarMenuList } from './AvatarMenuList';

const classes = {
  labelsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: (theme: Theme) => theme.spacing(2),
    minWidth: (theme: Theme) => theme.spacing(20),
    color: 'primary.contrastText',
  },
  toolBarColor: {
    opacity: 0.54,
  },
  avatar: {
    backgroundColor: 'primary.contrastText',
    color: 'primary.dark',
  },
} as const;

const UserAvatarMenu = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const currentUser: UserManagerUserEntity | undefined = useSelector(currentUserSelector);
  const organization: UserManagerUserOrganizationEntity | undefined = useSelector(currentOrganizationSelector);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  if (currentUser && organization) {
    return (
      <>
        <Stack
          sx={{ display: 'flex', flexDirection: 'row', ml: 2, cursor: 'pointer' }}
          aria-describedby={id}
          onClick={handleClick}
        >
          <Avatar alt={currentUser.email} sx={classes.avatar}>
            {currentUser.email ? currentUser.email.substring(0, 2).toUpperCase() : '?'}
          </Avatar>
          <Box sx={classes.labelsContainer}>
            <Typography variant="body2">{currentUser.email}</Typography>
            <Typography variant="caption" sx={classes.toolBarColor}>
              {organization.organization_name}
            </Typography>
          </Box>
        </Stack>
        <Popover
          sx={{ m: 1 }}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <AvatarMenuList user={currentUser} />
        </Popover>
      </>
    );
  }

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'row', ml: 2, cursor: 'pointer', border: ' 1px red solid' }}
      aria-describedby={id}
      onClick={handleClick}
    >
      <Skeleton animation="wave" variant="circular" width={40} height={40} sx={{ bgcolor: 'gray.500' }} />
      <Box sx={classes.labelsContainer}>
        <Typography variant="body2">
          <Skeleton animation="wave" sx={{ bgcolor: 'gray.500' }} />
        </Typography>
        <Typography variant="caption" sx={classes.toolBarColor}>
          <Skeleton animation="wave" sx={{ bgcolor: 'gray.500' }} />
        </Typography>
      </Box>
    </Box>
  );
};

export default UserAvatarMenu;
