import {DatasetManagerDatasetEntity} from '@biostrand/biostrandapi/javascript/dist/DatasetManagerApi';
import {Stack, Typography,} from '@mui/material';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {DataGrid, GridRenderCellParams} from "@mui/x-data-grid";
import {getDatasets} from "../datasetAPIUtils";
import {uniq} from "../../utilites/uniq";

interface DGSelectedItemsPlaceholderProps {
  selectedItemsIds?: string[];
}

interface DSMap {
  [key: string]: DatasetManagerDatasetEntity;
}

export const DGSelectedItemsPlaceholder = (props: DGSelectedItemsPlaceholderProps): JSX.Element => {
  const {selectedItemsIds} = props;

  const [itemsWithDSNames, setItemsWithDSNames] = useState([]);

  useEffect(() => {
    if (selectedItemsIds) {
      const datasetIds = uniq(selectedItemsIds.map(item => item.split('/')[0]));

      if (datasetIds) {
        const loadDatasets = async () => {
          const datasets = await getDatasets(datasetIds);
          const datasetsById: DSMap = {};
          datasets?.forEach(ds => {
            datasetsById[ds.id || ''] = ds;
          })

          const withNames = selectedItemsIds.map(wn => {
            const parts = wn.split('/');
            parts[0] = datasetsById[parts[0]].name;
            return parts.join('/');
          })
          setItemsWithDSNames(withNames);
        }
        loadDatasets();
      }

    }
  }, [selectedItemsIds]);

  const columns = [
    {
      field: 'name',
      headerName: 'name',
      minWidth: 170,
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => {
        const parts = params.row.split(`/`);
        const dsName = parts.shift();
        const filePath = `/` + parts.join('/');
        return (
          <Stack direction={"row"}>
            <Typography variant={"body2"} sx={{fontWeight: "bold"}} title={params.row}>{dsName}</Typography>
            {parts.length > 0 ? (<Typography variant={"body2"} title={params.row}>{filePath}</Typography>) : null}
          </Stack>
        );
      },
      sortable: true,
    }
  ]

  return (
    <Stack direction={'column'}
           sx={{minHeight: 300, overflowY: "scroll", flex: 1, minWidth: 300, alignItems: 'stretch'}} spacing={2}>
      <DataGrid
        sx={{
          '.MuiDataGrid-root': {border: '#f00 5px'},
          //  position: 'absolute',
          border: '5px',
          // top: 32,
          // bottom: 0,
          // left: -8,
          // right: -8,x
          flex: 1,
        }}
        initialState={{
          sorting: {
            //  sortModel: initialSort,
          },
        }}
        disableSelectionOnClick
        rows={itemsWithDSNames}
        loading={false}
        columns={columns}
        getRowId={row => row || ''}
        disableColumnFilter
        headerHeight={0}
        hideFooterRowCount
        hideFooterSelectedRowCount
        keepNonExistentRowsSelected
        density={'compact'}
      />
    </Stack>
  );
};
