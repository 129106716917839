import {ArrowBack} from '@mui/icons-material';
import {Button} from '@mui/material';
import {Stack} from '@mui/system';
import {push} from 'connected-react-router';
import * as React from 'react';
import {useDispatch} from 'react-redux';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useParams} from 'react-router';
import {RouteUrl} from '../../../../routeUrl';
import DatasetDetails from "@biostrand/components/src/datasets/DatasetDetails";

interface IDatasetParams {
    datasetName: string;
}

const DatasetDetailsSettingsContent = (): JSX.Element => {
    const {datasetName} = useParams<IDatasetParams>();

    const dispatch = useDispatch();

    return (
        <Stack sx={{m: 2, flex: 1}} direction={'column'}>
            <Stack direction={'row'}>
                <Button
                    startIcon={<ArrowBack/>}
                    onClick={() => {
                        dispatch(push(RouteUrl.DataSetsSettings));
                    }}>
                    Back to datasets
                </Button>
            </Stack>

            {datasetName && <DatasetDetails datasetId={datasetName}/>}


        </Stack>
    );
};

export default DatasetDetailsSettingsContent;
