import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Popover,
  Stack,
  Typography
} from '@mui/material';
import {useFormik} from 'formik';
import 'molstar/build/viewer/molstar.css';
import {StructureSelectionQuery} from 'molstar/lib/mol-plugin-state/helpers/structure-selection-query';
import {createPluginUI} from 'molstar/lib/mol-plugin-ui/';
import {parse} from 'molstar/lib/mol-script/transpile';
import {examples} from 'molstar/lib/mol-script/transpilers/pymol/examples'
import React, {useEffect, useRef, useState} from 'react';
import {HelpOutlineOutlined, Launch} from "@mui/icons-material";
import {PluginSpec} from "molstar/lib/mol-plugin/spec";
import {StateActions} from "molstar/lib/mol-plugin-state/actions";
import {
  BoxifyVolumeStreaming,
  CreateVolumeStreamingBehavior,
  InitVolumeStreaming
} from "molstar/lib/mol-plugin/behavior/dynamic/volume-streaming/transformers";
import {StateTransforms} from "molstar/lib/mol-plugin-state/transforms";
import {AssignColorVolume} from "molstar/lib/mol-plugin-state/actions/volume";
import {PluginBehaviors} from "molstar/lib/mol-plugin/behavior";
import {StructureFocusRepresentation} from "molstar/lib/mol-plugin/behavior/dynamic/selection/structure-focus-representation";
import {AnimateModelIndex} from "molstar/lib/mol-plugin-state/animation/built-in/model-index";
import {AnimateCameraSpin} from "molstar/lib/mol-plugin-state/animation/built-in/camera-spin";
import {AnimateCameraRock} from "molstar/lib/mol-plugin-state/animation/built-in/camera-rock";
import {AnimateStateInterpolation} from "molstar/lib/mol-plugin-state/animation/built-in/state-interpolation";
import {AnimateAssemblyUnwind} from "molstar/lib/mol-plugin-state/animation/built-in/assembly-unwind";
import {AnimateStateSnapshots} from "molstar/lib/mol-plugin-state/animation/built-in/state-snapshots";
import {AnimateStructureSpin} from "molstar/lib/mol-plugin-state/animation/built-in/spin-structure";
import {Color as MolColor} from 'molstar/lib/mol-util/color';
import Color from "color";
import {ParamDefinition} from "molstar/lib/mol-util/param-definition";
import {StructureComponentManager} from "molstar/lib/mol-plugin-state/manager/structure/component";
import {PluginStateObject} from "molstar/lib/mol-plugin-state/objects";
import {StructureSelection} from "molstar/lib/mol-model/structure/query";
import {setStructureOverpaint} from "molstar/lib/mol-plugin-state/helpers/structure-overpaint";
import {MolstarViewerLoadMore} from "./MolstarViewerLoadMore";
import {loadStructure, parsePyMOLColorCommand, PyMOLCommand, PyMOLContext} from "./MolstarUtils";
import {Script} from "molstar/lib/mol-script/script";
import {enqueueSnackbarNotification} from "../snackbarNotification/snackbarNotificationSlice";
import {CloseNotificationButton, CommonRuntimeVariables, RuntimeConfig} from "../index";
import {useDispatch} from "react-redux";
import MoldtarChatQuery from './MoldtarChatQuery';
import Structure = PluginStateObject.Molecule.Structure;

interface MolstarViewerProps {
  data: string;
  contentType: string;
  label: string;
  fileList: []
}

export const CustomPluginSpec = (): PluginSpec => ({
  actions: [
    PluginSpec.Action(StateActions.Structure.DownloadStructure),
    PluginSpec.Action(StateActions.Volume.DownloadDensity),
    PluginSpec.Action(StateActions.DataFormat.DownloadFile),
    PluginSpec.Action(StateActions.DataFormat.OpenFiles),
    PluginSpec.Action(StateActions.Structure.LoadTrajectory),
    PluginSpec.Action(StateActions.Structure.EnableModelCustomProps),
    PluginSpec.Action(StateActions.Structure.EnableStructureCustomProps),

    // Volume streaming
    PluginSpec.Action(InitVolumeStreaming),
    PluginSpec.Action(BoxifyVolumeStreaming),
    PluginSpec.Action(CreateVolumeStreamingBehavior),

    //PluginSpec.Action(StateTransforms.Data.Download),
    PluginSpec.Action(StateTransforms.Data.ParseCif),
    PluginSpec.Action(StateTransforms.Data.ParseCcp4),
    PluginSpec.Action(StateTransforms.Data.ParseDsn6),

    PluginSpec.Action(StateTransforms.Model.TrajectoryFromMmCif),
    PluginSpec.Action(StateTransforms.Model.TrajectoryFromCifCore),
    PluginSpec.Action(StateTransforms.Model.TrajectoryFromPDB),
    PluginSpec.Action(StateTransforms.Model.TransformStructureConformation),
    PluginSpec.Action(StateTransforms.Model.StructureFromModel),
    PluginSpec.Action(StateTransforms.Model.StructureFromTrajectory),
    PluginSpec.Action(StateTransforms.Model.ModelFromTrajectory),
    PluginSpec.Action(StateTransforms.Model.StructureSelectionFromScript),
    PluginSpec.Action(StateTransforms.Representation.StructureRepresentation3D),
    PluginSpec.Action(StateTransforms.Representation.StructureSelectionsDistance3D),
    PluginSpec.Action(StateTransforms.Representation.StructureSelectionsAngle3D),
    PluginSpec.Action(StateTransforms.Representation.StructureSelectionsDihedral3D),
    PluginSpec.Action(StateTransforms.Representation.StructureSelectionsLabel3D),
    PluginSpec.Action(StateTransforms.Representation.StructureSelectionsOrientation3D),
    PluginSpec.Action(StateTransforms.Representation.ModelUnitcell3D),
    PluginSpec.Action(StateTransforms.Representation.StructureBoundingBox3D),
    PluginSpec.Action(StateTransforms.Representation.ExplodeStructureRepresentation3D),
    PluginSpec.Action(StateTransforms.Representation.SpinStructureRepresentation3D),
    PluginSpec.Action(StateTransforms.Representation.UnwindStructureAssemblyRepresentation3D),
    PluginSpec.Action(StateTransforms.Representation.OverpaintStructureRepresentation3DFromScript),
    PluginSpec.Action(StateTransforms.Representation.TransparencyStructureRepresentation3DFromScript),
    PluginSpec.Action(StateTransforms.Representation.ClippingStructureRepresentation3DFromScript),
    PluginSpec.Action(StateTransforms.Representation.SubstanceStructureRepresentation3DFromScript),
    PluginSpec.Action(StateTransforms.Representation.ThemeStrengthRepresentation3D),

    PluginSpec.Action(AssignColorVolume),
    PluginSpec.Action(StateTransforms.Volume.VolumeFromCcp4),
    PluginSpec.Action(StateTransforms.Volume.VolumeFromDsn6),
    PluginSpec.Action(StateTransforms.Volume.VolumeFromCube),
    PluginSpec.Action(StateTransforms.Volume.VolumeFromDx),
    PluginSpec.Action(StateTransforms.Representation.VolumeRepresentation3D),
  ],
  behaviors: [
    PluginSpec.Behavior(PluginBehaviors.Representation.HighlightLoci),
    PluginSpec.Behavior(PluginBehaviors.Representation.SelectLoci),
    PluginSpec.Behavior(PluginBehaviors.Representation.DefaultLociLabelProvider),
    PluginSpec.Behavior(PluginBehaviors.Representation.FocusLoci),
    PluginSpec.Behavior(PluginBehaviors.Camera.FocusLoci),
    PluginSpec.Behavior(PluginBehaviors.Camera.CameraAxisHelper),
    PluginSpec.Behavior(PluginBehaviors.Camera.CameraControls),
    PluginSpec.Behavior(StructureFocusRepresentation),

    PluginSpec.Behavior(PluginBehaviors.CustomProps.StructureInfo),
    PluginSpec.Behavior(PluginBehaviors.CustomProps.AccessibleSurfaceArea),
    PluginSpec.Behavior(PluginBehaviors.CustomProps.BestDatabaseSequenceMapping),
    PluginSpec.Behavior(PluginBehaviors.CustomProps.Interactions),
    PluginSpec.Behavior(PluginBehaviors.CustomProps.SecondaryStructure),
    PluginSpec.Behavior(PluginBehaviors.CustomProps.ValenceModel),
    PluginSpec.Behavior(PluginBehaviors.CustomProps.CrossLinkRestraint),
  ],
  animations: [
    AnimateModelIndex,
    AnimateCameraSpin,
    AnimateCameraRock,
    AnimateStateSnapshots,
    AnimateAssemblyUnwind,
    AnimateStructureSpin,
    AnimateStateInterpolation
  ]
});

const applyColorToSelection = async (plugin: any, command: PyMOLCommand) => {
  const values = StructureComponentManager.getThemeParams(plugin, plugin.managers.structure.component.pivotStructure);
  const params = ParamDefinition.getDefaultValues(values);

  const selection = command.args[1];
  const color = command.args[0].indexOf('0x') === 0 ? new Color(Number(command.args[0])).rgbNumber() : new Color(command.args[0]).rgbNumber()

  if (selection) {
    const queryExpression = parse('pymol', selection);
    await plugin.dataTransaction(async (ctx) => {
      const xs = plugin.managers.structure.hierarchy.current.structures;
      if (xs.length === 0) return;
      const getLoci = async (ls: Structure) => {
        const sel = Script.getStructureSelection(queryExpression, ls);
        return StructureSelection.toLociWithSourceUnits(sel);
      }
      for (const s of xs) {
        await setStructureOverpaint(plugin, s.components, MolColor(Number(color)), getLoci, params.representations);
      }
    });

  } else {
    const values = StructureComponentManager.getThemeParams(plugin, plugin.managers.structure.component.pivotStructure);
    const params = ParamDefinition.getDefaultValues(values);
    await plugin.dataTransaction(async ctx => {
      const xs = plugin.managers.structure.hierarchy.current.structures;
      if (xs.length === 0) return;
      const getLoci = async (ls: Structure) => StructureSelection.toLociWithSourceUnits(await params.selection.getSelection(plugin, ctx, ls));
      for (const s of xs) {
        await setStructureOverpaint(plugin, s.components, MolColor(Number(color)), getLoci, params.representations);
      }
    });
  }
};

const extractContext = ( commands:PyMOLCommand[] ): PyMOLContext => {
  const context: PyMOLContext = {};
  commands.forEach( ( command ) => {
    if (command.command === "select") {
      if (command.args.length === 2) {
        context[command.args[0]] = command.args[1];
      }
    }
  });
  return context;
}
const applyContext = ( commands:PyMOLCommand[], context: PyMOLContext ): PyMOLCommand[] => {
  const newCommands = commands.map( ( command ) => {
    if (command.command === "color") {
      const selection = command.args[1];
      if (context[selection]) {
        command.args[1] = context[selection];
      }
    }

    return {...command};
  });
  return newCommands;
}

const applyCommand = async (plugin, command:PyMOLCommand) => {
  switch (command.command) {
    case 'color': {
      await applyColorToSelection(plugin, command);
      break
    }
    case 'select': {
      const queryExpression = parse('pymol', command.args[1]);
      const sq = StructureSelectionQuery('blub', queryExpression);
      plugin.managers.structure.selection.fromSelectionQuery('set', sq);
      break
    }
    default: {
      throw(new Error(`command ${command.command} ${command.args.join(', ')} is not supported yet`))
    }
  }
}

const runCommands = async (plugin, commandList: PyMOLCommand[]) => {
  for (let i=0;i<commandList.length; i++) {
    await applyCommand(plugin, commandList[i]);
  }
}

export const MolstarViewer = (props: MolstarViewerProps) => {
  const {data, contentType, label, fileList} = props;
  const parentRef = useRef(null);
  const plugin = useRef(null);
  const [initialized, setInitialized] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [commandsContext, setCommandsContext] = useState<PyMOLContext>([]);
  const dispatch = useDispatch();
  const isLLMVisible = !RuntimeConfig.isFeatureSupported(CommonRuntimeVariables.DisableMolStarLLM);

  const partialExamples = [{
    name: 'Highlight red Residues 50 to 100',
    value: 'color red, resi 50-100'
  }, ...examples.slice(0, 10)];

  const queryForm = useFormik({
    initialValues: {
      query: '',
    },
    onSubmit: async (values) => {
     try {
        const colorCommand = parsePyMOLColorCommand(values.query);
        if (colorCommand) {
          await applyColorToSelection(plugin.current, colorCommand)
        } else {
          const queryExpression = parse('pymol', values.query);
          const sq = StructureSelectionQuery('blub', queryExpression);
          plugin.current.managers.structure.selection.fromSelectionQuery('set', sq);
        }
      } catch (e: Error) {
        dispatch(
          enqueueSnackbarNotification({
            message: e.toString(),
            key: 'delete-error',
            options: {
              variant: 'warning',
              persist: false,
              action: key => <CloseNotificationButton
                notificationKey={key}>Close</CloseNotificationButton>,
            },
          }),
        );
      }
    }
  });

  useEffect(() => {
    const init = async () => {
      const spec = CustomPluginSpec();
      spec.layout = {
        initial: {
          isExpanded: false,
          controlsDisplay: 'reactive',
          showControls: false,
          // remoteState: 'none',
        },
      };
      spec.components = {
        remoteState: 'none'
      }
      plugin.current = await createPluginUI(parentRef.current, spec);
      await loadStructure(data, label, contentType, plugin.current, true);

      setInitialized(true);
    };

    init();

    return () => {
      plugin.current = null;
    };
  }, []);

  useEffect(() => {
    if (!initialized) return;
    (async () => {
      await loadStructure(data, label, contentType, plugin.current, true);
    })();
  }, [data]);

  const handleHelpClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHelpClose = () => {
    setAnchorEl(null);
  };

 const onCommand = async (commands) => {
   const context = extractContext(commands);
   const newContext = {...commandsContext, ...context};
   setCommandsContext(newContext);
   const cleanedContext = applyContext(commands, newContext);

   try{
    await runCommands(plugin.current, cleanedContext);
   } catch (e: Error) {
    dispatch(
      enqueueSnackbarNotification({
        message: e.toString(),
        key: 'commands-run-error',
        options: {
          variant: 'warning',
          persist: false,
          action: key => <CloseNotificationButton
            notificationKey={key}>Close</CloseNotificationButton>,
        },
      }),
    );
  }
 }

  return (
    <Stack direction={"column"}
           style={{
             position: 'relative',
             paddingTop: 8,
             flex: 1,
             alignItems: "center",
             left: 0,
             top: 0,
             right: 0,
             bottom: 0
           }}>
      <Stack direction={"row"} sx={{alignItems: "center", mt:1}} spacing={2} >
        {isLLMVisible ? <MoldtarChatQuery onCommand={onCommand} /> : null}

        <form onSubmit={queryForm.handleSubmit}>
          <Stack spacing={2} direction={"row"}>
            <FormControl sx={{minWidth: 400}} variant="outlined">
              <InputLabel htmlFor="pymolQuery" size={"small"}>PyMOL command</InputLabel>
              <OutlinedInput
                endAdornment={<InputAdornment position="end">
                  <IconButton size={"small"} onClick={handleHelpClick}><HelpOutlineOutlined/></IconButton>
                  <Popover
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    onClose={handleHelpClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <Stack sx={{p: 2}} spacing={1}>
                      <Typography variant={"body1"}>The viewer partially supports PyMOL
                        commands.</Typography><Typography variant={"body1"}>Examples:</Typography>
                      {partialExamples.map(example => <Stack direction={"row"} spacing={2} sx={
                        {alignItems: "center"}
                      }>
                        <Typography variant={"body2"}>{example.name}</Typography>
                        <Stack
                          spacing={1}
                          direction={"row"}
                          sx={{
                            fontFamily: 'monospace',
                            fontSize: 12,
                            p: 0.5,
                            backgroundColor: '#f6f6f6',
                            cursor: "pointer"
                          }}
                          onClick={() => {
                            queryForm.setFieldValue('query', example.value)
                            handleHelpClose();
                            queryForm.submitForm();
                          }}
                        >{example.value}<Launch sx={{ml: 1, fontSize: 14}}/></Stack>
                      </Stack>)}
                    </Stack>
                  </Popover>
                </InputAdornment>}
                aria-describedby="pymolQuery"
                inputProps={{
                  'aria-label': 'PyMOL command',
                }}
                id="pymolQuery"
                label="PyMOL command"
                size={"small"}
                sx={{minWidth: 400}}
                name="query"
                value={queryForm.values.query}
                onChange={queryForm.handleChange}
              />

            </FormControl>
            <Button
              variant="contained"
              color="primary"

              onClick={() => {
                queryForm.handleSubmit();
              }}
            >
              {'run'}
            </Button>
          </Stack>
        </form>
          <MolstarViewerLoadMore fileList={fileList} molPlugin={plugin.current}/>
      </Stack>
      <div ref={parentRef} style={{position: 'absolute', top: 60, right: 0, bottom: 0, left: 0}}/>
    </Stack>
  );
};

export default MolstarViewer;
