import {Button, Divider, Typography} from '@mui/material';
import {Stack} from '@mui/system';
import {GridSelectionModel} from '@mui/x-data-grid/models/gridSelectionModel';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {PopupWindow} from '../../popup/PopupWindow';
import {DGDatasetFileSelector} from "./DGDatasetFileSelector";
import {DGDatasetsSelector} from "../datasetGrid/DGDatasetsSelector";
import {useWindowDimensions} from "../../utilites/useWindowDimensions";
import {DGSelectedItemsList} from './DGSelectedItemsList';
import {ErrorPanel} from "../../ErrorPanel/ErrorPanel";
import {getDatasetByName} from '../datasetAPIUtils';

interface SelectDatasetPopupProps {
  popupKey: string;
  selectedDataset?: GridSelectionModel;
  open: boolean;
  onDatasetOrFileSelectionChange: (selectedDatasetIds: GridSelectionModel) => void;
  onCancel: () => void;
  showGroupFilter: boolean,
  selectedGroupId?: string,
}

export const MultiDsAndFilesSelect = (props: SelectDatasetPopupProps) => {
  const {onCancel, selectedDataset, open, onDatasetOrFileSelectionChange, showGroupFilter, selectedGroupId} = props;
  const [t] = useTranslation();

  const [currentSelection, setCurrentSelection] = useState<GridSelectionModel | undefined>(selectedDataset);
  const [openDataset, setOpenDataset] = useState<GridSelectionModel | undefined>(selectedDataset);
  const [selectedItems, setSelectedFile] = useState<GridSelectionModel | undefined>();
  const [dataset, setDataset] = useState<any | undefined>();

  const {height = 800, width = 900} = useWindowDimensions();

  useEffect(() => {
    if (currentSelection) {
      const loadDataset = async () => {
        const ds = await getDatasetByName(currentSelection[0])
        setDataset(ds);
      }
      loadDataset();
    }
  }, [currentSelection]);

  const onClose = () => {
    onCancel();
  };

  const onOpenDataset = (selection: GridSelectionModel) => {
    setOpenDataset(selection);
  };

  const onSelectionChange = (selection: GridSelectionModel) => {
    setCurrentSelection(selection);
    setSelectedFile(selection);
  };

  return (
    <PopupWindow open={open} title={t('Select dataset or file')} onClose={onClose}>
      <Stack sx={{height: height - 100, minHeight: height - 100, minWidth: width - 150, flex: 1}}>
        <Stack direction={'row'} sx={{minHeight: 600, flex: 1, alignItems: 'stretch'}} spacing={1}>
          <Stack sx={{flex: 1}}>
            <DGDatasetsSelector
              selectedItems={selectedItems}
              onSelectionChange={onSelectionChange}
              onDatasetOpen={onOpenDataset}
              showGroupFilter={showGroupFilter}
              selectedGroupId={selectedGroupId}

            />
          </Stack>
          <Stack sx={{borderLeft: '1px #dddddd solid'}}/>
          <Stack direction={'column'} sx={{flex: 1, minWidth: 250}}>
            {openDataset ? <DGDatasetFileSelector
              datasetId={String(openDataset[0])}
              onSelectionChange={fileIds => setSelectedFile(fileIds)}
              initialSelectedFileIds={selectedItems}
            /> : <ErrorPanel title={'No Selection'}
                             subtitle={'No dataset selected, select dataset on the left panel to see content'}/>
            }
          </Stack>
          <Stack sx={{borderLeft: '1px #dddddd solid'}}/>
          <Stack direction={'column'} sx={{flex: 1, minWidth: 250}} spacing={2}>
            <Typography>Selected Items</Typography>
            <Divider/>
            <DGSelectedItemsList selectedItemsIds={selectedItems}
                                 onSelectionChange={fileIds => setSelectedFile(fileIds)}/>
          </Stack>
        </Stack>
        <Stack direction={'row'} spacing={2} sx={{justifyContent: 'flex-end', mt: 2, mb: 2}}>
          {dataset && (
            <Typography sx={{flex: 1, fontWeight: '500'}} variant={'subtitle1'} color={'primary.main'}>
              {t('Selected: ')} {dataset.name}
            </Typography>
          )}
          <Button variant={'outlined'} onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button
            variant={'contained'}
            disabled={!selectedItems}
            onClick={() => {
              onDatasetOrFileSelectionChange &&
              selectedItems &&
              onDatasetOrFileSelectionChange(selectedItems);
            }}
          >
            {t('Select')}
          </Button>
        </Stack>
      </Stack>
    </PopupWindow>
  );
};
